import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "flag-icons/css/flag-icons.min.css";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  document.title = t("signup.9");
  const headerTitle = t("signup.9");
  const [value, setValue] = useState("");
  const getCountryCode = (phoneNumber) => {
    if (!phoneNumber) return "";
    const countryCode = phoneNumber.split(" ")[0];
    return countryCode;
  };

  return (
    <div className="login-section mx-3">
      <Header title={headerTitle} showProfile={false} />
      <h2>{t("signup.8")}</h2>
      <div className="top-section">
        <div className="name-surname-section">
          <form action="" className="d-flex flex-column">
            <label className="mb-1">{t("signup.1")}</label>
            <input type="text" />
          </form>
        </div>
        <div className="phone-number-section">
          <form action="">
            <div>
              <label htmlFor="" className="mb-1">
                {t("signup.2")}
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                international
                defaultCountry="AZ"
                countryCallingCodeEditable={false}
              />
              {value && (
                <div>
                  <span
                    className={`fi fi-${getCountryCode(value)
                      .replace("+", "")
                      .toLowerCase()}`}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="button-section">
          <Link to="/otpverification">
            <button className="btn py-3 global-button">{t("signup.3")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
