import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // API'ye POST isteği gönderme
      const response = await axios.post(
        "https://api.emotix.dev/api/v1/e-prorab/auth/sign-in",
        { email, password }
      );

      if (response.data.user.id === 1 && response.data.token) {
        const token = response.data.token;

        localStorage.setItem("authToken", token);
        navigate("/dashboard");
      } else {
        alert("Login or Password is wrong!");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Xəta baş verdi!");
    }
  };

  return (
    <div className="login-section px-3">
      <h2 className="text-center my-3">Admin Login</h2>
      <form onSubmit={handleLogin} className="d-flex flex-column gap-2">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Şifre"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="btn btn-dark">
          Giriş
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
