import React, { useEffect, useState } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SquareLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const MyHome = () => {
  const { t } = useTranslation();
  document.title = t("myHome.0");
  const headerTitle = t("myHome.0");
  const backwardNavigate = "/account";
  const navigate = useNavigate();

  const handlePropertyClick = (roomName) => {
    const formattedRoomName = roomName.toLowerCase().replace(/\s+/g, "-");
    navigate(`/account/myhome/${formattedRoomName}`);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [shareId, setShareId] = useState("");
  const [sharedRepair, setSharedRepair] = useState({});
  const repairId = localStorage.getItem("repairId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/share/${repairId}`)
      .then((response) => {
        const result = response.data.split("/").slice(6).join("/");
        setShareId(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [repairId]);

  useEffect(() => {
    if (shareId) {
      setIsLoading(true);
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${shareId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSharedRepair(response.data);
          console.log(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [shareId, token]);

  const property = sharedRepair.property || {};
  const rooms = property.rooms || [];

  const groupedRooms = rooms.reduce((acc, room) => {
    const spaceName = room.roomType.space.name.text;
    if (!acc[spaceName]) {
      acc[spaceName] = [];
    }
    acc[spaceName].push(room);
    return acc;
  }, {});

  return (
    <div className="myhome-section px-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : property ? (
        <div>
          {Object.entries(groupedRooms).map(([spaceName, roomList], index) => (
            <div key={index} className="myhome-section">
              <h4 className="mt-3">{spaceName}</h4>
              <div className="room-list">
                {roomList.map((room, roomIndex) => (
                  <div
                    className="section d-flex align-items-center"
                    onClick={() => handlePropertyClick(room.roomType.name.text)}
                    style={{ cursor: "pointer" }}
                    key={roomIndex}
                  >
                    <div className="left-section">
                      <i className={`${room.roomType.iconType}`}></i>
                    </div>
                    <div className="right-section d-flex align-items-center justify-content-between w-100">
                      <div className="property-title ms-3">
                        <h2>{room.roomType.name.text}</h2>
                      </div>
                      <div className="detail-section me-2">
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h4 className="text-center mt-4">{t("myHome.1")}</h4>
      )}
    </div>
  );
};

export default MyHome;
