import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CalculatorOnboarding from "../pages/CalculatorOnboarding/CalculatorOnboarding";
import CatalogOnboarding from "../pages/CatalogOnboarding/CatalogOnboarding";
import MonitoringOnboarding from "../pages/MonitoringOnboarding/MonitoringOnboarding";
import ForemanOnboarding from "../pages/ForemanOnboarding/ForemanOnboarding";
import Home from "../pages/Home/Home";
import Calculator from "../pages/Calculator/Calculator";
import DimensionRoom from "../pages/DimensionRoom/DimensionRoom";
import Material from "../pages/Material/Material";
import Maintenance from "../pages/Maintenance/Maintenance";
import Signup from "../pages/Signup/Signup";
import OTPverification from "../pages/OTPverification/OTPverification";
import SuccessLogin from "../pages/SuccessLogin/SuccessLogin";
import AllMaterials from "../pages/AllMaterials/AllMaterials";
import AllMaterialsPrice from "../pages/AllMaterialsPrice/AllMaterialsPrice";
import Account from "../pages/Account/Account";
import AccountDetails from "../pages/Account/Details/AccountDetails";
import AccountDetailsEdit from "../pages/Account/Details/Edit/AccountDetailsEdit";
import HomePlan from "../pages/Account/HomePlan/HomePlan";
import HomePlanDetails from "../pages/Account/HomePlan/HomePlanDetails/HomePlanDetails";
import Contact from "../pages/Account/Contact/Contact";
import RepairHistory from "../pages/Account/RepairHistory/RepairHistory";
import Terms from "../pages/Account/Terms/Terms";
import PrivacyPolicy from "../pages/Account/PrivacyPolicy/PrivacyPolicy";
import AdminLogin from "../pages/admin/pages/AdminLogin";
import Dashboard from "../pages/admin/pages/Dashboard";
import ProtectedRoute from "../pages/admin/auth/ProtectedRoute";
import PropertyTypeManager from "../pages/admin/components/PropertyTypeManager/PropertyTypeManager";
import RepairTariffManager from "../pages/admin/components/RepairTariffManager/RepairTariffManager";
import RepairStyleManager from "../pages/admin/components/RepairStyleManager/RepairStyleManager";
import TechnicalAreaManager from "../pages/admin/components/TechnicalAreaManager/TechnicalAreaManager";
import SpaceManager from "../pages/admin/components/SpaceManager/SpaceManager";
import RoomTypeManager from "../pages/admin/components/RoomTypeManager/RoomTypeManager";
import MaterialCategoryManager from "../pages/admin/components/MaterialCategoryManager/MaterialCategoryManager";
import MaterialManager from "../pages/admin/components/MaterialManager/MaterialManager";
import DependentMaterialManager from "../pages/admin/components/DependentMaterialManager/DependentMaterialManager";
import StoreManager from "../pages/admin/components/StoreManager/StoreManager";
import Shops from "../pages/Shops/Shops";
import Login from "../pages/Login/Login";
import MyForeman from "../pages/Account/MyForeman/MyForeman";
import MyHome from "../pages/Account/MyHome/MyHome";
import StatusController from "../pages/StatusController/StatusController";
import RepairManManager from "../pages/admin/components/RepairManManager/RepairManManager";
import MyHomeDetails from "../pages/Account/MyHome/MyHomeDetails/MyHomeDetails";
import Entry from "../pages/Entry/Entry";
import PropertyStatusManager from "../pages/admin/components/PropertyStatusManager/PropertyStatusManager";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Entry />} />
        <Route
          path="/calculatoronboarding"
          element={<CalculatorOnboarding />}
        />
        <Route path="/catalogonboarding" element={<CatalogOnboarding />} />
        <Route
          path="/monitoringonboarding"
          element={<MonitoringOnboarding />}
        />
        <Route path="/foremanonboarding" element={<ForemanOnboarding />} />
        <Route path="/home" element={<Home />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/dimensionroom" element={<DimensionRoom />} />
        <Route path="/material" element={<Material />} />
        <Route path="/maintenance/:id" element={<Maintenance />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otpverification" element={<OTPverification />} />
        <Route path="/successlogin" element={<SuccessLogin />} />
        <Route path="/allmaterials/:id" element={<AllMaterials />} />
        <Route path="/allmaterialsprice/:id" element={<AllMaterialsPrice />} />
        <Route path="/shops/:id" element={<Shops />} />
        <Route path="/account" element={<Account />} />
        <Route path="/account/details" element={<AccountDetails />} />
        <Route path="/account/homeplan" element={<HomePlan />} />
        <Route path="/account/myhome" element={<MyHome />} />
        <Route path="/account/myforeman" element={<MyForeman />} />
        <Route path="/account/contact" element={<Contact />} />
        <Route path="/account/repairhistory" element={<RepairHistory />} />
        <Route path="/account/terms" element={<Terms />} />
        <Route path="/account/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/account/repairhistory" element={<RepairHistory />} />
        <Route
          path="/propertytypemanager"
          element={
            <ProtectedRoute>
              <PropertyTypeManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/propertystatusmanager"
          element={
            <ProtectedRoute>
              <PropertyStatusManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/repairmanmanager"
          element={
            <ProtectedRoute>
              <RepairManManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/repairtariffmanager"
          element={
            <ProtectedRoute>
              <RepairTariffManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/repairstylemanager"
          element={
            <ProtectedRoute>
              <RepairStyleManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/storemanager"
          element={
            <ProtectedRoute>
              <StoreManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/technicalareamanager"
          element={
            <ProtectedRoute>
              <TechnicalAreaManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/spacemanager"
          element={
            <ProtectedRoute>
              <SpaceManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/roomtypemanager"
          element={
            <ProtectedRoute>
              <RoomTypeManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/materialcategorymanager"
          element={
            <ProtectedRoute>
              <MaterialCategoryManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/materialmanager"
          element={
            <ProtectedRoute>
              <MaterialManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dependentmaterialmanager"
          element={
            <ProtectedRoute>
              <DependentMaterialManager />
            </ProtectedRoute>
          }
        />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account/homeplan/:propertyType"
          element={<HomePlanDetails />}
        />
        <Route path="/account/myhome/:roomType" element={<MyHomeDetails />} />
        <Route path="/account/details/edit" element={<AccountDetailsEdit />} />
        <Route path="/signup/status" element={<StatusController />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
