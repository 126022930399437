import React, { useState, useEffect } from "react";
import axios from "axios";

const StoreManager = () => {
  const [store, setStore] = useState({
    name: { az: "", en: "", ru: "" },
    phoneNumber: "",
    image: {
      id: null,
      status: true,
    },
    status: true,
    materials: [],
  });
  const [stores, setStores] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [categories, setCategories] = useState([]);
  const [repairStyles, setRepairStyles] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [repairStyleId, setRepairStyleId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchCategories();
    fetchRepairStyles();
    fetchStores();
  }, []);

  const fetchMaterials = (categoryId, repairStyleId) => {
    if (categoryId && repairStyleId) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/materials/filter?categoryId=${categoryId}&repairStyleId=${repairStyleId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((response) => {
          setMaterials(response.data);
        })
        .catch((error) => console.error("Failed to fetch materials:", error));
    }
  };

  const fetchCategories = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => console.error("Failed to fetch categories:", error));
  };

  const fetchRepairStyles = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setRepairStyles(response.data);
      })
      .catch((error) => console.error("Failed to fetch repair styles:", error));
  };

  const fetchStores = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/stores/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => console.error("Failed to fetch stores:", error));
  };

  const handleInput = (event) => {
    const { name, value, dataset } = event.target;
    const field = dataset.field;

    if (["az", "en", "ru"].includes(name)) {
      setStore((prevType) => ({
        ...prevType,
        [field]: { ...prevType[field], [name]: value },
      }));
    } else {
      setStore((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event) => {
    const fileInput = event.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          setStore((prevStore) => ({
            ...prevStore,
            image: { ...prevStore.image, id: imageId },
          }));
        })
        .catch((error) => console.log("Failed to upload image:", error));
    } else {
      console.log("No file selected");
    }
  };

  const handleMaterialSelect = (materialId) => {
    const isSelected = store.materials.some(
      (material) => material.id === materialId
    );

    if (isSelected) {
      setStore((prevStore) => ({
        ...prevStore,
        materials: prevStore.materials.filter(
          (material) => material.id !== materialId
        ),
      }));
    } else {
      setStore((prevStore) => ({
        ...prevStore,
        materials: [...prevStore.materials, { id: materialId, status: true }],
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const storeData = { ...store };

    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/stores/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/stores";

    const request = isEditing
      ? axios.put(url, storeData, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, storeData, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setStore({
          name: { az: "", en: "", ru: "" },
          phoneNumber: "",
          image: { id: null, status: true },
          materials: [],
          status: true,
        });
        fetchStores();
        alert("Store saved successfully!");
      })
      .catch((error) => {
        console.error("Failed to submit store:", error);
        alert(`Error: ${error.response?.data?.message || "Unknown error"}`);
      });
  };

  const handleEdit = (store) => {
    setIsEditing(true);
    setEditId(store.id);
    setStore(store);
  };

  const handleDelete = (storeId) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/stores/${storeId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        fetchStores();
        alert("Store deleted successfully!");
      })
      .catch((error) => {
        console.error("Failed to delete store:", error);
      });
  };

  return (
    <div className="store-manager px-3">
      <h3 className="text-center text-primary">Store Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="store-name">
          <h4 className="text-center fw-bold">Name</h4>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={store.name.az}
            onChange={handleInput}
            name="az"
            data-field="name"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={store.name.en}
            onChange={handleInput}
            name="en"
            data-field="name"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={store.name.ru}
            onChange={handleInput}
            name="ru"
            data-field="name"
            required
          />
          <h4 className="text-center mt-3 fw-bold">Phone Number</h4>
          <input
            type="text"
            name="phoneNumber"
            value={store.phoneNumber}
            onChange={handleInput}
            required
          />
          <h4 className="text-center my-2 fw-bold">Upload Store Image</h4>
          <input
            type="file"
            onChange={handleImageUpload}
            required={!isEditing}
          />
          <h4 className="text-center fw-bold my-3">Material Selection</h4>
          <div className="d-flex align-items-center justify-content-around">
            <select
              onChange={(e) => {
                setCategoryId(e.target.value);
                fetchMaterials(e.target.value, repairStyleId);
              }}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name.text}
                </option>
              ))}
            </select>

            <select
              onChange={(e) => {
                setRepairStyleId(e.target.value);
                fetchMaterials(categoryId, e.target.value);
              }}
            >
              <option value="">Select Repair Style</option>
              {repairStyles.map((repairStyle) => (
                <option key={repairStyle.id} value={repairStyle.id}>
                  {repairStyle.name.text}
                </option>
              ))}
            </select>
          </div>
          <h5 className="text-center my-3">Available Materials</h5>
          <ol>
            {materials.map((material) => (
              <li
                key={material.id}
                className={`mb-3 ${
                  store.materials.some((m) => m.id === material.id)
                    ? "selected-material"
                    : ""
                }`}
              >
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => handleMaterialSelect(material.id)}
                >
                  {material.name.az}
                </button>
              </li>
            ))}
          </ol>
        </div>
        <button className="btn btn-primary">
          {isEditing ? "Edit" : "Submit"}
        </button>
      </form>

      <h5 className="text-center my-3">My Stores</h5>
      <div className="d-flex flex-column gap-3">
        <div className="table-responsive">
          <table className="table table-striped m-0">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  №
                </th>
                <th scope="col">Name (az)</th>
                <th scope="col">Name (en)</th>
                <th scope="col">Name (ru)</th>
                <th scope="col">Phone Number </th>
                <th scope="col">Image</th>
                <th scope="col">Materials</th>
                <th scope="col" className="text-center">
                  Edit
                </th>
                <th scope="col" className="text-center">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {stores.map((type, index) => {
                const base64String = `data:image/png;base64,${type.image.hashBase64}`;
                return (
                  <tr key={type.id}>
                    <th scope="row" className="text-center">
                      {index + 1}
                    </th>
                    <td>{type.name.az}</td>
                    <td>{type.name.en}</td>
                    <td>{type.name.ru}</td>
                    <td>{type.phoneNumber}</td>
                    <td>
                      {" "}
                      <img
                        src={base64String}
                        width={150}
                        alt={type.text}
                        className="design-style-image"
                      />
                    </td>
                    <td>
                      {materials.map((type, index) => (
                        <span key={index}>{type.name.az}</span>
                      ))}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-warning me-3"
                        onClick={() => handleEdit(type)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => handleDelete(type.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StoreManager;
