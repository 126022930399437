import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { SquareLoader } from "react-spinners";

export const RepairTariffContext = createContext("");
export const RepairTariffProvider = ({ children }) => {
  const [repairTariff, setRepairTariff] = useState([]);
  const [selectedRepairTariff, setSelectedRepairTariff] = useState(
    JSON.parse(sessionStorage.getItem("selectedRepairTariff")) || null
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-tariffs")
      .then((response) => {
        setRepairTariff(response.data);
      })
      .catch((error) => {
        console.error("Error fetching repair tariffs:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (selectedRepairTariff) {
      sessionStorage.setItem(
        "selectedRepairTariff",
        JSON.stringify(selectedRepairTariff)
      );
    }
  }, [selectedRepairTariff]);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SquareLoader
          color="#8c920b"
          cssOverride={{
            position: "absolute",
            top: "45%",
            bottom: "45%",
          }}
          size={100}
          speedMultiplier={1}
        />
      </div>
    );
  }
  return (
    <RepairTariffContext.Provider
      value={[repairTariff, setSelectedRepairTariff, selectedRepairTariff]}
    >
      {children}
    </RepairTariffContext.Provider>
  );
};
