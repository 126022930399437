import React from "react";
import { useTranslation } from "react-i18next";

export const GoogleLoginButton = () => {
  const { t } = useTranslation();
  const handleGoogleLogin = () => {
    window.location.href = "https://api.emotix.dev/oauth2/authorization/google";
  };

  return (
    <button
      onClick={handleGoogleLogin}
      className="btn py-3 mb-4 d-flex align-items-center justify-content-center gap-2"
    >
      <i className="fa-brands fa-google fs-3"></i>
      {t("signup.6")}
    </button>
  );
};
