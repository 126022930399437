import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const RoomTypeContext = createContext("");

export const RoomTypeProvider = ({ children }) => {
  const [roomType, setRoomType] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState("");

  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/room-types")
      .then((response) => {
        setRoomType(response.data);
      })
      .catch((error) => {
        console.error("Error fetching room types:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedRoomType) {
      sessionStorage.setItem(
        "selectedRoomType",
        JSON.stringify(selectedRoomType)
      );
    }
  }, [selectedRoomType]);

  return (
    <RoomTypeContext.Provider
      value={[roomType, setSelectedRoomType, selectedRoomType]}
    >
      {children}
    </RoomTypeContext.Provider>
  );
};
