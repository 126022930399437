import React, { useEffect, useState } from "react";
import HeaderAccount from "../../../../components/HeaderAccount";
import axios from "axios";
import { SquareLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const HomePlanDetails = () => {
  const { t } = useTranslation();
  document.title = t("homePlanDetails.0");
  const headerTitle = t("homePlanDetails.0");
  const backwardNavigate = "/account/homeplan";

  const [openRoom, setOpenRoom] = useState(null);
  const handleToggle = (room) => {
    setOpenRoom(openRoom === room ? null : room);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [shareId, setShareId] = useState("");
  const [sharedRepair, setSharedRepair] = useState({});
  const repairId = localStorage.getItem("repairId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/share/${repairId}`)
      .then((response) => {
        const result = response.data.split("/").slice(6).join("/");
        setShareId(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [repairId]);

  useEffect(() => {
    if (shareId) {
      setIsLoading(true);
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${shareId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSharedRepair(response.data);
          console.log(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [shareId, token]);

  const property = sharedRepair.property || {};
  const rooms = property.rooms || [];

  return (
    <div className="home-plan-details-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <>
          <div className="top-section">
            <div className="d-flex align-items-center property">
              <div className="property-icon me-2">
                <i className={`${property.propertyType?.iconType}`}></i>
              </div>
              <div className="property-title">
                <h5>{property.propertyType?.name?.text}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label htmlFor="area">{t("homePlanDetails.1")} (m²) :</label>
                <input
                  type="text"
                  value={property.area || ""}
                  id="areaHouse"
                  readOnly
                />
              </div>
              <div className="col-6">
                <label>{t("homePlanDetails.2")} (m) :</label>
                <input
                  type="text"
                  value={property.height || ""}
                  id="heightWall"
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="room-section">
            <h5>{t("homePlanDetails.3")}</h5>
            <div className="row g-2">
              {rooms.map((type) => (
                <div className="col-12" key={type.id}>
                  <div className="d-flex align-items-center justify-content-between room-title">
                    <h3>{type.roomType.name.text}</h3>
                    <i
                      className={
                        openRoom === type.id
                          ? "fa-solid fa-chevron-down"
                          : "fa-solid fa-chevron-right"
                      }
                      onClick={() => handleToggle(type.id)}
                    ></i>
                  </div>
                  {openRoom === type.id && (
                    <div className="room">
                      <div className="row g-3">
                        <div className="col-6">
                          <form>
                            <label>
                              {t("homePlanDetails.4")} (m) :
                              <input
                                type="text"
                                value={type.height || ""}
                                readOnly
                              />
                            </label>
                          </form>
                        </div>
                        <div className="col-6">
                          <form>
                            <label>
                              {t("homePlanDetails.5")} (m²) :
                              <input
                                type="text"
                                value={type.area || ""}
                                readOnly
                              />
                            </label>
                          </form>
                        </div>
                        <div className="col-6">
                          <form>
                            <label>
                              {t("homePlanDetails.6")} (m) :
                              <input
                                type="text"
                                value={type.width || ""}
                                readOnly
                              />
                            </label>
                          </form>
                        </div>
                        <div className="col-6">
                          <form>
                            <label>
                              {t("homePlanDetails.7")} (m) :
                              <input
                                type="text"
                                value={type.length || ""}
                                readOnly
                              />
                            </label>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomePlanDetails;
