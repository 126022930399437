import React, { useState, useEffect } from "react";
import RoomDropdown from "../../components/RoomDropdown/RoomDropdown";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import SelectedDate from "../../assets/icons/SelectedDate.svg";
import SelectedTime from "../../assets/icons/SelectedTime.svg";
import RoomAdd from "../../assets/icons/RoomAdd.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
const DimensionRoom = () => {
  const { t } = useTranslation();
  const theme = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {},
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            fontSize: "0.5rem",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            width: "44px",
            height: "44px",
            fontSize: "1rem",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            padding: "22px",
            width: "44px",
            height: "44px",
            fontSize: "1rem",
          },
        },
      },
    },
  });
  document.title = t("dimensionRoom.0");
  const headerTitle = t("dimensionRoom.0");
  const navigate = useNavigate();
  const loadInitialRoomValues = () => {
    const savedValues = sessionStorage.getItem("roomValues");
    return savedValues
      ? JSON.parse(savedValues).map((room) => ({
          ...room,
          area: parseFloat(room.area),
          height: parseFloat(room.height),
          width: parseFloat(room.width),
          length: parseFloat(room.length),
        }))
      : [];
  };
  const loadSavedDate = () => {
    const savedDate = sessionStorage.getItem("selectedDate");
    return savedDate ? dayjs(savedDate) : dayjs();
  };
  const loadSavedTime = () => {
    const savedTime = sessionStorage.getItem("selectedTime");
    return savedTime ? dayjs(savedTime) : dayjs();
  };
  const [content, setContent] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [roomValues, setRoomValues] = useState(loadInitialRoomValues);
  const [value, setValue] = useState(loadSavedDate);
  const [time, setTime] = useState(loadSavedTime);
  const handleRoomChange = (newRoom, iconType) => {
    setSelectedRoom(newRoom);
    if (!roomValues.some((room) => room.id === newRoom.id)) {
      setRoomValues((prevValues) => [
        ...prevValues,
        { id: newRoom.id, name: { text: newRoom.name.text }, iconType },
      ]);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value ? parseFloat(value) : "";
    setRoomValues((prevValues) => {
      const updatedValues = prevValues.map((room) => {
        if (room.id === selectedRoom?.id) {
          const updatedRoom = { ...room, [name]: numericValue };
          if (name === "width" || name === "length") {
            const width = updatedRoom.width || 0;
            const length = updatedRoom.length || 0;
            updatedRoom.area = width * length;
          }

          return updatedRoom;
        }
        return room;
      });

      if (!updatedValues.some((room) => room.id === selectedRoom?.id)) {
        const newRoom = {
          id: selectedRoom?.id || "",
          name: selectedRoom?.name || { text: "Unknown Room" },
          iconType: selectedRoom?.iconType || "",
          [name]: numericValue,
        };

        if (name === "width" || name === "length") {
          const width = newRoom.width || 0;
          const length = newRoom.length || 0;
          newRoom.area = width * length;
        }

        return [...updatedValues, newRoom];
      }

      return updatedValues;
    });
  };
  const [openRoom, setOpenRoom] = useState(null);
  const handleToggle = (room) => {
    setOpenRoom(openRoom === room ? null : room);
  };
  useEffect(() => {
    const roomsWithValues = roomValues
      .filter((room) => room.area && room.height && room.width && room.length)
      .map((room) => ({
        ...room,
        area: parseFloat(room.area),
        height: parseFloat(room.height),
        width: parseFloat(room.width),
        length: parseFloat(room.length),
      }));
    sessionStorage.setItem("roomValues", JSON.stringify(roomsWithValues));
  }, [roomValues]);
  const hasRoomBeenSaved = (roomId) => {
    return roomValues.some((room) => room.id === roomId);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const currentRoomValues = roomValues.find(
      (room) => room.id === selectedRoom?.id
    );

    // Kontrol yapın
    if (
      !currentRoomValues?.height ||
      !currentRoomValues?.width ||
      !currentRoomValues?.length
    ) {
      toast.error("Lütfen tüm oda değerlerini doldurun.", {
        position: "top-right",
      });
      return; // Eğer değerlere eksiklik varsa fonksiyonu durdur
    }

    // Kontrol: Oda daha önce kaydedilmiş mi?
    if (!hasRoomBeenSaved(selectedRoom.id)) {
      // İlk kez kaydedilir
      setRoomValues((prevValues) => [...prevValues, currentRoomValues]);
    }
  };
  const completedRooms = roomValues.filter(
    (room) => room.height && room.width && room.length
  );
  const handleForward = () => {
    navigate("/material");
  };
  const sendRepairStyleID = JSON.parse(
    sessionStorage.getItem("selectedRepairStyle")
  ).id;
  const selectedPropertyType = JSON.parse(
    sessionStorage.getItem("selectedPropertyType")
  );
  const sendPropertyStatusID = JSON.parse(
    sessionStorage.getItem("selectedPropertyStatuses")
  ).id;
  const selectedHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  console.log(roomValues);
  const sendPropertyTypeID = JSON.parse(
    sessionStorage.getItem("selectedPropertyType")
  ).id;
  const sendRepairTariffID = JSON.parse(
    sessionStorage.getItem("selectedRepairTariff")
  ).id;
  const sendHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  const selectedDate = value.format("YYYY-MM-DD");
  const selectedTime = time.format("HH:mm:ss");
  const selectedDateTime = selectedDate + "T" + selectedTime;
  const data = {
    repairStyle: {
      id: sendRepairStyleID,
    },
    repairTariff: {
      id: sendRepairTariffID,
    },
    repairmanDate: selectedDateTime,
    property: {
      propertyType: {
        id: sendPropertyTypeID,
      },
      propertyStatus: {
        id: sendPropertyStatusID,
      },
      area: sendHomeData.areaHouse,
      height: sendHomeData.heightWall,
      roomCount: sendHomeData.roomCount,
      doorCount: sendHomeData.roomDoorCount,
      status: true,
    },
    status: true,
  };
  const handleSubmitForeman = (e) => {
    let data2 = JSON.stringify(data);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.emotix.dev/api/v1/e-prorab/repairman-dates",
      headers: {
        "Content-Type": "application/json",
      },
      data: data2,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        console.log(response.data);
        const repairId = response.data.id;
        localStorage.setItem("repairId", repairId);
        navigate("/signup");
      })
      .catch((error) => {
        console.log(error);
      });
    e.preventDefault();
  };

  const renderContent = () => {
    if (content === 1) {
      return (
        <>
          <div className="room-selection-section px-2">
            <div className="home-data d-flex align-items-center gap-3 mb-3">
              <i className={`${selectedPropertyType.iconType}`}></i>
              <h4 className="m-0"> {selectedPropertyType.name.text}</h4>
            </div>
            <div className="row">
              <div className="col-6">
                <label htmlFor="area">{t("calculator.2")} (m²)</label>
                <input
                  type="text"
                  value={selectedHomeData.areaHouse || ""}
                  id="areaHouse"
                  readOnly
                />
              </div>
              <div className="col-6">
                <label>{t("calculator.3")} (m)</label>
                <input
                  type="text"
                  value={selectedHomeData.heightWall || ""}
                  id="heightWall"
                  readOnly
                />
              </div>
            </div>
            <button
              className="room-addition btn d-flex align-items-center gap-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
            >
              <div className="left-section">
                <img src={RoomAdd} alt="" />
              </div>
              <div className="right-section">
                <h4>{t("dimensionRoom.12")}</h4>
                <p>{t("dimensionRoom.13")}</p>
              </div>
            </button>
            <div className="room-section">
              <h5>{t("dimensionRoom.14")}</h5>
              <div className="row g-2">
                {completedRooms.length > 0 ? (
                  completedRooms.map((type) => (
                    <div className="col-12" key={type.id}>
                      <div className="d-flex align-items-center justify-content-between room-title">
                        <h3>{type.name.text}</h3>
                        <i
                          className={
                            openRoom === type.id
                              ? "fa-solid fa-chevron-down"
                              : "fa-solid fa-chevron-right"
                          }
                          onClick={() => handleToggle(type.id)}
                        ></i>
                      </div>
                      {openRoom === type.id && (
                        <div className="room">
                          <div className="row g-3">
                            <div className="col-6">
                              <form>
                                <label>
                                  {t("dimensionRoom.1")} (m) :
                                  <input
                                    type="text"
                                    value={type.width || ""}
                                    readOnly
                                  />
                                </label>
                              </form>
                            </div>
                            <div className="col-6">
                              <form>
                                <label>
                                  {t("dimensionRoom.2")} (m) :
                                  <input
                                    type="text"
                                    value={type.length || ""}
                                    readOnly
                                  />
                                </label>
                              </form>
                            </div>
                            <div className="col-6">
                              <form>
                                <label>
                                  {t("dimensionRoom.3")} (m) :
                                  <input
                                    type="text"
                                    value={type.height || ""}
                                    readOnly
                                  />
                                </label>
                              </form>
                            </div>
                            <div className="col-6">
                              <form>
                                <label>
                                  {t("dimensionRoom.4")} (m²) :
                                  <input
                                    type="text"
                                    value={type.area || ""}
                                    readOnly
                                  />
                                </label>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <h5 className="text-center">Boş</h5>
                )}
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <RoomDropdown onChange={handleRoomChange} />
                </div>
                <div className="modal-body">
                  {selectedRoom && (
                    <div className="bottom-section d-flex align-items-center justify-content-between">
                      <form>
                        <div className="row mb-3">
                          <div className="col-6">
                            <div className="width-of-house d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.1")} (m) :
                              </label>
                              <input
                                type="number"
                                name="width"
                                placeholder="m"
                                value={
                                  roomValues.find(
                                    (room) => room.id === selectedRoom.id
                                  )?.width || ""
                                }
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="length-of-room d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.2")} (m) :
                              </label>
                              <input
                                type="number"
                                name="length"
                                placeholder="m"
                                value={
                                  roomValues.find(
                                    (room) => room.id === selectedRoom.id
                                  )?.length || ""
                                }
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="height-of-wall d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.3")} (m) :
                              </label>
                              <input
                                required
                                type="number"
                                name="height"
                                placeholder="m"
                                value={
                                  roomValues.find(
                                    (room) => room.id === selectedRoom.id
                                  )?.height || ""
                                }
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="area-of-house d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.4")} ( m²) :
                              </label>
                              <input
                                type="number"
                                name="area"
                                placeholder="m²"
                                value={
                                  roomValues.find(
                                    (room) => room.id === selectedRoom.id
                                  )?.area || ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                <div class="modal-footer">
                  <button
                    className="btn w-100"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="button-section">
            <button className="btn py-3 global-button" onClick={handleForward}>
              {t("dimensionRoom.5")}
            </button>
          </div>
          <ToastContainer />
        </>
      );
    } else if (content === 2) {
      return (
        <div className="calendar-section">
          <h5 className="mb-3">{t("dimensionRoom.6")}</h5>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <div className="d-flex align-items-center justify-content-between bg-white px-3 mt-2 time-selection">
                  <DateCalendar
                    disablePast
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                  />
                </div>
              </DemoContainer>
            </ThemeProvider>
            <div className="d-flex align-items-center justify-content-between bg-white px-3 mt-2 time-selection">
              <div className="left-side">{t("dimensionRoom.7")}</div>
              <div className="right-side">
                {" "}
                <TimePicker
                  value={time}
                  onChange={(newTime) => setTime(newTime)}
                  ampm
                />
              </div>
            </div>
          </LocalizationProvider>

          <div className="chosen-calendar mt-3 px-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="chosen-text">
                <h6>{t("dimensionRoom.8")}</h6>
              </div>
              <div className="chosen-date d-flex align-items-center gap-1">
                <img src={SelectedDate} alt="calendar" />
                <h6>{value.format("DD MMMM")}</h6>
              </div>
              <div className="chosen-time d-flex align-items-center gap-1">
                <img src={SelectedTime} alt="clock" />
                <h6>{time.format("hh:mm A")}</h6>
              </div>
            </div>
          </div>
          <div className="button-section">
            <button
              className="btn py-3 global-button"
              onClick={handleSubmitForeman}
            >
              {t("dimensionRoom.9")}
            </button>
          </div>
          <ToastContainer />
        </div>
      );
    }
  };

  return (
    <div className="dimesion-room-section mx-3">
      <Header title={headerTitle} showProfile={true} />
      <div className="top-section mt-3 d-flex align-items justify-content-around">
        <div className="section section-1">
          <button
            className="btn px-5"
            onClick={() => setContent(1)}
            style={{
              backgroundColor: content === 1 ? "#7B7D51" : "transparent",
              color: content === 1 ? "#ffffff" : "#7B7D51",
            }}
          >
            {t("dimensionRoom.10")}
          </button>
        </div>
        <div className="section section-2">
          <button
            className="btn px-5"
            onClick={() => setContent(2)}
            style={{
              backgroundColor: content === 2 ? "#7B7D51" : "transparent",
              color: content === 2 ? "#ffffff" : "#7B7D51",
            }}
          >
            {t("dimensionRoom.11")}
          </button>
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default DimensionRoom;
