import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DefaultProfileImage from "../../../../assets/images/default-profile-photo.jpg";
import { useTranslation } from "react-i18next";

const AccountDetailsEdit = () => {
  const { t } = useTranslation();
  document.title = t("accountDetailsEdit.0");
  const storedUserInfo = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const defaultUserInfo = {
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    address: "",
    image: null,
  };
  const [profilePicture, setProfilePicture] = useState(
    storedUserInfo?.image || defaultUserInfo.image
  );

  const [userInfo, setUserInfo] = useState({
    name: storedUserInfo?.name || defaultUserInfo.name,
    surname: storedUserInfo?.surname || defaultUserInfo.surname,
    email: storedUserInfo?.email || defaultUserInfo.email,
    phoneNumber: storedUserInfo?.phoneNumber || defaultUserInfo.phoneNumber,
    address: storedUserInfo?.address || defaultUserInfo.address,
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setProfilePicture(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const userId = storedUserInfo?.id;
    const updatedUserInfo = {
      ...userInfo,
      image: profilePicture,
    };

    try {
      setLoading(true);
      const response = await axios.put(
        `https://api.emotix.dev/api/v1/e-prorab/users/${userId}`,
        updatedUserInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(updatedUserInfo));
        alert("Məlumatlar uğurla yeniləndi!");
        navigate("/account/details");
      } else {
        alert("Məlumatların yenilənməsində xəta baş verdi!");
      }
    } catch (error) {
      console.error("Error updating user information:", error);
      alert("Məlumatların yenilənməsi zamanı xəta baş verdi!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-details-edit-section px-3">
      <header className="d-flex align-items-center gap-2">
        <Link className="link" to="/account/details">
          <h1>{t("accountDetailsEdit.1")}</h1>
        </Link>
        <Link className="link" to="/account/details">
          <i className="fa-solid fa-xmark mt-2"></i>
        </Link>
      </header>
      <div className="top-section d-flex align-items-center justify-content-center flex-column">
        <img
          src={profilePicture || DefaultProfileImage}
          alt="profil"
          className="mb-2"
        />
        <button
          className="btn text-decoration-none"
          onClick={() => document.getElementById("profile-image-input").click()}
        >
          <h5>{t("accountDetailsEdit.2")}</h5>
        </button>
        <input
          type="file"
          id="profile-image-input"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleProfilePictureChange}
        />
      </div>
      <div className="middle-section">
        <form>
          <div className="name-section">
            <label htmlFor="">{t("accountDetailsEdit.3")}</label>
            <input
              type="text"
              value={userInfo.name}
              onChange={(e) =>
                setUserInfo({ ...userInfo, name: e.target.value })
              }
            />
          </div>
          <div className="surname-section">
            <label htmlFor="">{t("accountDetailsEdit.4")}</label>
            <input
              type="text"
              value={userInfo.surname}
              onChange={(e) =>
                setUserInfo({ ...userInfo, surname: e.target.value })
              }
            />
          </div>
          <div className="email-section">
            <label htmlFor="">{t("accountDetailsEdit.5")}</label>
            <input
              type="text"
              value={userInfo.email}
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
            />
          </div>
          <div className="phone-number-section">
            <label htmlFor="">{t("accountDetailsEdit.6")}</label>
            <input
              type="text"
              value={userInfo.phoneNumber}
              onChange={(e) =>
                setUserInfo({ ...userInfo, phoneNumber: e.target.value })
              }
            />
          </div>
          <div className="adress-section">
            <label htmlFor="">{t("accountDetailsEdit.7")}</label>
            <input
              type="text"
              value={userInfo.address}
              onChange={(e) =>
                setUserInfo({ ...userInfo, address: e.target.value })
              }
            />
          </div>
        </form>
      </div>
      <div className="button-section mb-1">
        <button
          className="btn global-button py-3"
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? "Yüklənir..." : "Yadda Saxla"}
        </button>
      </div>
    </div>
  );
};

export default AccountDetailsEdit;
