import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { SquareLoader } from "react-spinners";

export const RepairStyleContext = createContext("");
export const RepairStyleProvider = ({ children }) => {
  const [repairStyle, setRepairStyle] = useState([]);
  const [selectedRepairStyle, setSelectedRepairStyle] = useState(
    JSON.parse(sessionStorage.getItem("selectedRepairStyle")) || null
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles")
      .then((response) => {
        setRepairStyle(response.data);
      })
      .catch((error) => {
        console.error("Error fetching repair styles:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedRepairStyle) {
      sessionStorage.setItem(
        "selectedRepairStyle",
        JSON.stringify(selectedRepairStyle)
      );
    }
  }, [selectedRepairStyle]);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SquareLoader
          color="#8c920b"
          cssOverride={{
            position: "absolute",
            top: "45%",
            bottom: "45%",
          }}
          size={100}
          speedMultiplier={1}
        />
      </div>
    );
  }
  return (
    <RepairStyleContext.Provider
      value={[repairStyle, setSelectedRepairStyle, selectedRepairStyle]}
    >
      {children}
    </RepairStyleContext.Provider>
  );
};
