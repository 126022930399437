import React, { useState, useEffect, useRef, useContext } from "react";
import RoomBasic from "../../assets/images/RoomBasic.png";
import { Link, useNavigate } from "react-router-dom";
import RoomDropdown from "../../components/RoomDropdown/RoomDropdown";
import { MaterialContext } from "../../context/MaterialContext";
import { MaterialCategoryContext } from "../../context/MaterialCategory";
import Header from "../../components/Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SquareLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
const Material = () => {
  const { t } = useTranslation();
  document.title = t("material.0");
  const headerTitle = t("material.0");
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null);
  const [selectedMaterials, setSelectedMaterials] = useState(() => {
    return JSON.parse(sessionStorage.getItem("selectedMaterial")) || {};
  });
  const [roomImage, setRoomImage] = useState(RoomBasic);
  const dropdownRef = useRef(null);
  const [materialCategory] = useContext(MaterialCategoryContext) || [];
  const [material, , , fetchMaterials] = useContext(MaterialContext) || {};
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const selectedRepairTariff =
    JSON.parse(sessionStorage.getItem("selectedRepairTariff")) || {};
  const selectedRoomTypes =
    JSON.parse(sessionStorage.getItem("roomValues")) || [];
  const [selectedRoom, setSelectedRoom] = useState("Qonaq otağı");
  console.log(selectedRoomTypes);
  useEffect(() => {
    if (activeSection && !hasLoadedOnce) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setHasLoadedOnce(true);
      }, 2000);
    }
  }, [activeSection, hasLoadedOnce]);

  const handleSectionClick = (categoryId) => {
    const selectedRepairStyle = JSON.parse(
      sessionStorage.getItem("selectedRepairStyle")
    );
    const repairStyleId = selectedRepairStyle?.id;

    if (repairStyleId) {
      fetchMaterials?.(categoryId, repairStyleId);
      setActiveSection(categoryId === activeSection ? null : categoryId);
    } else {
      console.error("Repair style ID not found in local storage.");
    }
    const dropdownHeight = window.innerHeight * 0.3;
    document.documentElement.style.setProperty(
      "--dropdown-height",
      `${dropdownHeight}px`
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveSection(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRoomChange = (newRoom) => {
    setSelectedRoom(newRoom.id);
  };

  const handleMaterialSelect = (categoryId, item) => {
    setSelectedMaterials((prevSelected) => {
      const newSelected = { ...prevSelected };

      if (!newSelected[selectedRoom]) {
        newSelected[selectedRoom] = { roomType: selectedRoom, materials: [] };
      }

      const roomMaterials = newSelected[selectedRoom].materials;

      const existingMaterialIndex = roomMaterials.findIndex(
        (mat) => mat.categoryId === categoryId
      );

      if (existingMaterialIndex !== -1) {
        if (roomMaterials[existingMaterialIndex].id === item.id) {
          roomMaterials.splice(existingMaterialIndex, 1);
        } else {
          roomMaterials[existingMaterialIndex] = {
            categoryId,
            id: item.id,
            price:
              selectedRepairTariff.id === 1
                ? item.lowPrice
                : selectedRepairTariff.id === 2
                ? item.midPrice
                : selectedRepairTariff.id === 3
                ? item.highPrice
                : item.price,
            currency: item.currency,
          };
        }
      } else {
        roomMaterials.push({
          categoryId,
          id: item.id,
          price:
            selectedRepairTariff.id === 1
              ? item.lowPrice
              : selectedRepairTariff.id === 2
              ? item.midPrice
              : selectedRepairTariff.id === 3
              ? item.highPrice
              : item.price,
          currency: item.currency,
        });
      }

      const newData = {
        roomTypes: Object.values(newSelected).map((roomDetails) => ({
          roomType: roomDetails.roomType,
          materials: roomDetails.materials,
        })),
      };

      sessionStorage.setItem("selectedMaterial", JSON.stringify(newData));

      if (item.imageOnRoom?.hashBase64) {
        const base64String = `data:image/png;base64,${item.imageOnRoom.hashBase64}`;
        setRoomImage(base64String);
      }

      return newSelected;
    });

    setActiveSection(null);
  };
  // POST Repair API
  const sendRepairStyleID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedRepairStyle")).id
  );
  const sendPropertyTypeID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedPropertyType")).id
  );
  const sendRepairTariffID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedRepairTariff")).id
  );
  const sendPropertyStatusID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedPropertyStatuses")).id
  );
  const sendHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  const sendRoom = JSON.parse(sessionStorage.getItem("roomValues"));

  const data = {
    repairStyle: {
      id: sendRepairStyleID,
    },
    repairTariff: {
      id: sendRepairTariffID,
    },
    property: {
      propertyType: {
        id: sendPropertyTypeID,
      },
      propertyStatus: {
        id: sendPropertyStatusID,
      },
      area: parseInt(sendHomeData.areaHouse),
      height: parseInt(sendHomeData.heightWall),
      roomCount: parseInt(sendHomeData.roomCount),
      doorCount: parseInt(sendHomeData.roomDoorCount),
      rooms: sendRoom.map((room) => {
        const roomMaterials = selectedMaterials[room.id]?.materials || [];
        return {
          roomType: {
            id: parseInt(room.id),
          },
          width: parseInt(room.width),
          length: parseInt(room.length),
          area: parseInt(room.area),
          height: parseInt(room.height),
          roomComponents: roomMaterials.map((material) => ({
            materialCategory: {
              id: parseInt(material.categoryId),
            },
            material: {
              id: parseInt(material.id),
            },
            status: true,
          })),
          status: true,
        };
      }),
      status: true,
    },
    status: true,
  };

  const postRepairData = () => {
    const hasMaterials = data.property.rooms.some(
      (room) => room.roomComponents.length > 0
    );
    if (!hasMaterials) {
      toast.error("Zəhmət olmasa material seçin", {
        position: "top-right",
      });
      return;
    }

    axios
      .post("https://api.emotix.dev/api/v1/e-prorab/repairs", data)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        console.log(response.data);
        const repairId = response.data.id;
        localStorage.setItem("repairId", repairId);
        navigate(`/maintenance/${repairId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="material-section px-3">
      <Header title={headerTitle} showProfile={true} />
      <div className="top-section">
        <div className="img-button-section mb-4">
          <RoomDropdown
            onChange={handleRoomChange}
            selectedRoomTypes={selectedRoomTypes}
          />
        </div>
        <img src={roomImage} alt="Room" />
      </div>
      <div className="middle-section">
        <div className="material-category-buttons gap-3">
          {materialCategory?.map((type) => (
            <button
              key={type.id}
              className="btn"
              onClick={() => handleSectionClick(type.id)}
              style={{
                cursor: !selectedRoom ? "not-allowed" : "pointer",
                opacity: !selectedRoom ? 0.5 : 1,
              }}
            >
              {type.name.text}
            </button>
          ))}
        </div>
      </div>
      {loading}{" "}
      <>
        {activeSection && (
          <div ref={dropdownRef} className="dropdown-section active">
            <div className="dropdown-content slide-up">
              {loading ? (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <SquareLoader
                    color="#8c920b"
                    cssOverride={{
                      position: "absolute",
                      top: "45%",
                      left: "45%",
                    }}
                    size={50}
                    speedMultiplier={1}
                  />
                </div>
              ) : material.filter((item) =>
                  item.categories?.some(
                    (category) => category.id === activeSection
                  )
                ).length > 0 ? (
                material
                  .filter((item) =>
                    item.categories?.some(
                      (category) => category.id === activeSection
                    )
                  )
                  .map((item) => {
                    const base64String = `data:image/png;base64,${item.image.hashBase64}`;
                    const isSelected =
                      selectedMaterials[selectedRoom]?.[activeSection]?.id ===
                      item.id;

                    const price =
                      selectedRepairTariff.id === 1
                        ? item.lowPrice
                        : selectedRepairTariff.id === 2
                        ? item.midPrice
                        : selectedRepairTariff.id === 3
                        ? item.highPrice
                        : item.price;

                    return (
                      <button
                        key={item.id}
                        className="btn"
                        onClick={() =>
                          handleMaterialSelect(activeSection, item)
                        }
                        style={{ opacity: isSelected ? 0.6 : 1 }}
                      >
                        <div className="material-box">
                          <img src={base64String} alt="Material" />
                          <h5>{item.title}</h5>
                          <p>
                            1 kv.m. - {price} {item.currency}
                          </p>
                        </div>
                      </button>
                    );
                  })
              ) : (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <h3>{t("material.1")}</h3>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <div className="button-section">
        <button className="btn py-3 global-button" onClick={postRepairData}>
          {t("material.2")}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Material;
