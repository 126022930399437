import axios from "axios";
import React, { useEffect, useState } from "react";

const RepairStyleManager = () => {
  const [repairStyleAdmin, setRepairStyleAdmin] = useState([]);
  const [repairStyle, setRepairStyle] = useState({
    name: { az: "", en: "", ru: "" },
    image: {
      id: null,
      status: true,
    },
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    fetchRepairStyles();
  }, []);

  const fetchRepairStyles = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRepairStyleAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setRepairStyle((prevType) => ({
      ...prevType,
      name: { ...prevType.name, [name]: value },
    }));
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event) => {
    // Ensure the event target is the input element
    const fileInput = event.target;

    // Check if files are available
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];

      // Proceed with the image upload
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          // Update the store with the image ID
          setRepairStyle((prevStore) => ({
            ...prevStore,
            image: { ...prevStore.image, id: imageId },
          }));
        })
        .catch((error) => {
          console.log("Failed to upload image:", error);
        });
    } else {
      console.log("No file selected");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repair-styles/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repair-styles";

    const request = isEditing
      ? axios.put(url, repairStyle, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, repairStyle, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRepairStyle({
          name: { az: "", en: "", ru: "" },
          image: { id: null, status: true },
          status: true,
        });
        fetchRepairStyles();
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (type) => {
    setRepairStyle(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repair-styles/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairStyles())
      .catch((error) => console.log(error));
  };

  return (
    <div className="repair-style-manager px-3">
      <h3 className="text-center text-primary">Repair Style Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="repair-style-name">
          <h5 className="text-center">Repair Style Name</h5>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={repairStyle.name.az}
            onChange={handleInput}
            name="az"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={repairStyle.name.en}
            onChange={handleInput}
            name="en"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={repairStyle.name.ru}
            onChange={handleInput}
            name="ru"
            required
          />
          <label htmlFor="image">Upload Image</label>
          <input
            type="file"
            onChange={handleImageUpload}
            name="image"
            accept="image/*"
            required={!isEditing}
          />
        </div>
        <button className="btn btn-primary">
          {isEditing ? "Edit" : "Submit"}
        </button>
      </form>

      <h5 className="text-center my-3">My Repair Style Types</h5>
      <div className="d-flex flex-column gap-3">
        <div className="table-responsive">
          <table className="table table-striped m-0">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  №
                </th>
                <th scope="col">Name (az)</th>
                <th scope="col">Name (en)</th>
                <th scope="col">Name (ru)</th>
                <th scope="col">Style Image</th>
                <th scope="col" className="text-center">
                  Edit
                </th>
                <th scope="col" className="text-center">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {repairStyleAdmin.map((type, index) => {
                const base64String = `data:image/png;base64,${type.image.hashBase64}`;
                return (
                  <tr key={type.id}>
                    <th scope="row" className="text-center">
                      {index + 1}
                    </th>
                    <td>{type.name.az}</td>
                    <td>{type.name.en}</td>
                    <td>{type.name.ru}</td>
                    <td>
                      {" "}
                      <img
                        src={base64String}
                        width={150}
                        alt={type.name.az}
                        className="design-style-image"
                      />
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-warning me-3"
                        onClick={() => handleEdit(type)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => handleDelete(type.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RepairStyleManager;
