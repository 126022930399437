import React from "react";
import iphone15Img3 from "../../assets/images/iPhone15_3.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderAccount from "../../components/HeaderAccount";
const MonitoringOnboarding = () => {
  document.title = "Giriş";
  const { t } = useTranslation();
  const backwardNavigate = "/catalogonboarding";
  const pass = "Keç";
  return (
    <div className="onboarding-monitoring-section mx-3">
      <HeaderAccount backwardNavigate={backwardNavigate} pass={pass} />
      <div className="img-section">
        <img
          src={iphone15Img3}
          alt="iphone 15 online calculator"
          className="img"
        />
      </div>
      <div className="text-section">
        <h4>{t("monitoringOnboarding.0")}</h4>
        <p>{t("catalogOnboarding.1")}</p>
      </div>
      <div className="button-section">
        <Link to="/foremanonboarding">
          <button className="btn py-3">{t("catalogOnboarding.2")}</button>
        </Link>
      </div>
    </div>
  );
};

export default MonitoringOnboarding;
