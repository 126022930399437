import React from "react";
import iphone15Img4 from "../../assets/images/iPhone15_4.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderAccount from "../../components/HeaderAccount";
const ForemanOnboarding = () => {
  const { t } = useTranslation();
  document.title = "Giriş";
  const backwardNavigate = "/monitoringonboarding";
  const pass = "Keç";

  return (
    <div className="onboarding-foreman-section mx-3">
      <HeaderAccount backwardNavigate={backwardNavigate} pass={pass} />
      <div className="img-section">
        <img
          src={iphone15Img4}
          alt="iphone 15 online calculator"
          className="img"
        />
      </div>
      <div className="text-section">
        <h4>{t("foremanOnboarding.0")}</h4>
        <p>{t("foremanOnboarding.1")}</p>
      </div>
      <div className="button-section">
        <Link to="/home">
          <button className="btn py-3">{t("foremanOnboarding.2")}</button>
        </Link>
      </div>
    </div>
  );
};

export default ForemanOnboarding;
