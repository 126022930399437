import React, { useEffect, useState } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import axios from "axios";
import { SquareLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
const RepairHistory = () => {
  const { t } = useTranslation();
  document.title = t("repairHistory.0");
  const headerTitle = t("repairHistory.0");
  const backwardNavigate = "/account";

  const [isLoading, setIsLoading] = useState(true);
  const [shareId, setShareId] = useState("");
  const [sharedRepair, setSharedRepair] = useState(null);
  const repairId = localStorage.getItem("repairId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/share/${repairId}`)
      .then((response) => {
        const result = response.data.split("/").slice(6).join("/");
        setShareId(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [repairId]);

  useEffect(() => {
    if (shareId) {
      setIsLoading(true);
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${shareId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSharedRepair(response.data);
          console.log(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [shareId, token]);
  const property = sharedRepair?.property || {};
  const rooms = property.rooms || [];

  return (
    <div className="repair-history-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : property ? (
        <>
          {" "}
          <div className="top-section d-flex align-items-center gap-2">
            {property.propertyType && (
              <>
                <i className={`${property.propertyType.iconType}`}></i>
                <h4>{property.propertyType.name.text}</h4>
              </>
            )}
          </div>
          <div className="bottom-section">
            <div className="row g-3">
              {rooms.map((type, index) => (
                <div className="col-12 room-section" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="left">
                      <i className={`${type.roomType.iconType} mx-3`}></i>
                    </div>
                    <div className="right">
                      <h6>{type.roomType.name.text}</h6>
                      <p>
                        {sharedRepair?.startDate || "2025"} -
                        {sharedRepair?.endDate || "2025"}
                      </p>
                    </div>
                  </div>
                  <hr className="horizontal-line" />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <h4 className="text-center mt-4">{t("repairHistory.1")}</h4>
      )}
    </div>
  );
};

export default RepairHistory;
