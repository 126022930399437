import React from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const { t } = useTranslation();
  document.title = t("contact.0");
  const headerTitle = t("contact.0");
  const backwardNavigate = "/account";
  return (
    <div className="account-contact-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="top-section">
        <div className="row g-3">
          <div className="col-12">
            <div className="cellphone-section section">
              <i className="bi bi-telephone"></i>
              <h5>+994 12 345 6789</h5>
            </div>
          </div>
          <div className="col-12">
            <div className="whatsapp-section section">
              <i className="bi bi-whatsapp"></i>
              <h5>+994 12 345 6789</h5>
            </div>
          </div>
          <div className="col-12">
            <div className="mail-section section">
              <i className="bi bi-envelope"></i>
              <h5>info@eprorabaz.com</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-section">
        <h2>{t("contact.1")}</h2>
      </div>
      <div className="bottom-section">
        <div className="row g-1">
          <div className="col-12">
            <div className="instagram-section social-media-section">
              <i className="bi bi-instagram"></i>
              <a href="/">https://www.instagram.com</a>
            </div>
          </div>
          <div className="col-12">
            <div className="facebook-section social-media-section">
              {" "}
              <i className="bi bi-facebook"></i>
              <a href="/">https://www.facebook.com</a>
            </div>
          </div>
          <div className="col-12">
            <div className="telegram-section social-media-section">
              {" "}
              <i className="bi bi-telegram"></i>
              <a href="/">https://www.telegram.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
