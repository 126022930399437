import React, { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "flag-icons/css/flag-icons.min.css";
import Header from "../../components/Header";
import { GoogleLoginButton } from "../../components/auth/GoogleLoginButton";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const { t } = useTranslation();
  document.title = t("signup.9");
  const headerTitle = t("signup.9");
  const [name, setName] = useState("");
  const [value, setValue] = useState("");

  const isFormValid = name.trim() !== "" && value !== "";

  return (
    <div className="signup-section mx-3">
      <Header title={headerTitle} showProfile={false} />
      <h2>{t("signup.0")}</h2>
      <div className="top-section">
        <div className="name-surname-section">
          <form action="" className="d-flex flex-column">
            <label className="mb-1">{t("signup.1")}</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </form>
        </div>
        <div className="phone-number-section">
          <form action="">
            <div>
              <label htmlFor="" className="mb-1">
                {t("signup.2")}
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                international
                defaultCountry="AZ"
                countryCallingCodeEditable={false}
              />
            </div>
          </form>
        </div>
        <div className="button-section">
          <Link to={isFormValid ? "/otpverification" : "#"}>
            <button
              className="btn py-3 global-button mt-3 "
              style={{
                color: "#ffffff",
                border: isFormValid ? "1px solid #8c920b" : "1px solid #C4C4C4",
                backgroundColor: isFormValid ? "#8c920b" : "#C4C4C4",
                cursor: isFormValid ? "pointer" : "not-allowed",
              }}
              disabled={!isFormValid}
            >
              {t("signup.3")}
            </button>
          </Link>
        </div>
      </div>
      <div className="middle-section my-4">
        <div className="d-flex align-items-center justify-content-around">
          <div className="line"></div>
          <div className="middle d-flex align-items-center">
            <span className="mx-3">{t("signup.4")}</span>
          </div>
          <div className="line"></div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="button-section">
          <button className="btn py-3 mb-4 d-flex align-items-center justify-content-center gap-2">
            <i className="fa-brands fa-facebook fs-3"></i>
            {t("signup.5")}
          </button>
          <GoogleLoginButton />
        </div>
        <div className="sign-in-section d-flex align-items-center justify-content-center">
          <h6>
            {t("signup.7")} &nbsp;
            <Link to="/login" className="login">
              {t("signup.8")}
            </Link>{" "}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Signup;
