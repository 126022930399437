import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const SpaceContext = createContext("");
export const SpaceProvider = ({ children }) => {
  const [space, setSpace] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/spaces")
      .then((response) => {
        setSpace(response.data);
      })
      .catch((error) => {
        console.error("Error fetching space : ", error);
      });
  }, []);
  return (
    <SpaceContext.Provider value={[space, setSpace]}>
      {children}
    </SpaceContext.Provider>
  );
};
