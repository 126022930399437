import React, { useState } from "react";
import CalculatorImg from "../../assets/images/Calculator.png";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Calculator = () => {
  const { t } = useTranslation();
  document.title = t("calculator.0");
  const headerTitle = t("calculator.0");
  const navigate = useNavigate();
  const storedData = JSON.parse(sessionStorage.getItem("homeData")) || {
    areaHouse: "",
    heightWall: "",
    roomCount: 0,
    roomDoorCount: 0,
  };

  const [inputData, setInputData] = useState({
    areaHouse: storedData.areaHouse,
    heightWall: storedData.heightWall,
  });
  const [roomCount, setRoomCount] = useState(storedData.roomCount);
  const [roomDoorCount, setRoomDoorCount] = useState(storedData.roomDoorCount);

  const handleData = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value ? parseFloat(value) : "" });
  };

  const handleSubmit = () => {
    if (!inputData.areaHouse || !inputData.heightWall) {
      toast.error("Zəhmət olmasa evin ölçülərini daxil edin", {
        position: "top-right",
      });
      return;
    }
    if (roomCount === 0) {
      toast.error("Zəhmət olmasa otaq sayını daxil edin", {
        position: "top-right",
      });
      return;
    }
    if (roomDoorCount === 0) {
      toast.error("Zəhmət olmasa qapı sayını daxil edin", {
        position: "top-right",
      });
      return;
    }
    const allData = {
      areaHouse: inputData.areaHouse,
      heightWall: inputData.heightWall,
      roomCount: roomCount,
      roomDoorCount: roomDoorCount,
    };
    sessionStorage.setItem("homeData", JSON.stringify(allData));
    navigate("/dimensionroom");
  };

  const handleIncreaseRoomCount = () => {
    setRoomCount(roomCount + 1);
  };

  const handleDecreaseRoomCount = () => {
    if (roomCount !== 0) {
      setRoomCount(roomCount - 1);
    }
  };

  const handleIncreaseRoomDoorCount = () => {
    setRoomDoorCount(roomDoorCount + 1);
  };

  const handleDecreaseRoomDoorCount = () => {
    if (roomDoorCount !== 0) {
      setRoomDoorCount(roomDoorCount - 1);
    }
  };

  return (
    <div className="calculator-section mx-3">
      <Header title={headerTitle} showProfile={true} />
      <div className="top-section mb-5">
        <img src={CalculatorImg} alt="calculator" />
        <div className="text-section text-center">
          <h4>{t("calculator.1")}</h4>
        </div>
      </div>
      <div className="middle-section d-flex align-items-center justify-content-between">
        <form action="">
          <div className="row">
            <div className="col-6">
              <div className="area-of-house d-flex flex-column">
                <label className="mb-3">{t("calculator.2")} (m²) :</label>
                <input
                  type="number"
                  name="areaHouse"
                  value={inputData.areaHouse}
                  onChange={handleData}
                  placeholder="m²"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="height-of-wall d-flex flex-column">
                <label className="mb-3">{t("calculator.3")} (m) :</label>
                <input
                  type="number"
                  name="heightWall"
                  value={inputData.heightWall}
                  onChange={handleData}
                  placeholder="m"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="bottom-section">
        <div className="bottom-top">
          <h4>{t("calculator.4")}</h4>
          <div className="d-flex align-items-center justify-content-between gap-3">
            <button className="btn" onClick={handleDecreaseRoomCount}>
              -
            </button>
            <p>{roomCount}</p>
            <button className="btn" onClick={handleIncreaseRoomCount}>
              +
            </button>
          </div>
          <h4 className="mt-3">
            {roomCount ? `${t("calculator.7")} ${roomCount}` : ""}{" "}
          </h4>
        </div>
        <div className="bottom-bottom">
          <h4>{t("calculator.5")}</h4>
          <div className="d-flex align-items-center justify-content-between gap-3">
            <button className="btn" onClick={handleDecreaseRoomDoorCount}>
              -
            </button>
            <p>{roomDoorCount}</p>
            <button className="btn" onClick={handleIncreaseRoomDoorCount}>
              +
            </button>
          </div>
          <h4 className="mt-3">
            {roomDoorCount ? `${t("calculator.8")} ${roomDoorCount}` : ""}{" "}
          </h4>
        </div>
      </div>
      <div className="button-section">
        <button
          type="button"
          className="btn py-3 global-button"
          onClick={handleSubmit}
        >
          {t("calculator.6")}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Calculator;
