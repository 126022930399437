import axios from "axios";
import React, { useEffect, useState } from "react";

const PropertyTypeManager = () => {
  const [propertyTypeAdmin, setPropertyTypeAdmin] = useState([]);
  const [propertyType, setPropertyType] = useState({
    name: { az: "", en: "", ru: "" },
    iconType: "",
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    fetchPropertyTypes();
  }, []);

  const fetchPropertyTypes = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/property-types/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setPropertyTypeAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    if (name === "az" || name === "en" || name === "ru") {
      setPropertyType((prevType) => ({
        ...prevType,
        name: { ...prevType.name, [name]: value },
      }));
    } else {
      setPropertyType((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/property-types/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/property-types";

    const axiosMethod = isEditing ? axios.put : axios.post;

    axiosMethod(apiUrl, propertyType, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setPropertyType({
          name: { az: "", en: "", ru: "" },
          iconType: "",
          status: true,
        });
        fetchPropertyTypes();
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (type) => {
    setPropertyType(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/property-types/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchPropertyTypes())
      .catch((error) => console.log(error));
  };

  return (
    <div className="property-type-manager px-3">
      <h3 className="text-center text-primary">Property Type Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="property-type-name">
          <h5 className="text-center">Property Name</h5>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={propertyType.name.az}
            onChange={handleInput}
            name="az"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={propertyType.name.en}
            onChange={handleInput}
            name="en"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={propertyType.name.ru}
            onChange={handleInput}
            name="ru"
            required
          />
          <label htmlFor="iconType">Icon Type</label>
          <input
            type="text"
            value={propertyType.iconType}
            onChange={handleInput}
            name="iconType"
            required
          />
        </div>
        <button className="btn btn-primary">
          {isEditing ? "Edit" : "Submit"}
        </button>
      </form>
      <h5 className="text-center my-3">My Property Types</h5>
      <div className="table-responsive">
        <table className="table table-striped m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Icon Type</th>
              <th scope="col" className="text-center">
                Edit
              </th>
              <th scope="col" className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {propertyTypeAdmin.map((type, index) => (
              <tr key={type.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                <td>{type.name.az}</td>
                <td>{type.name.en}</td>
                <td>{type.name.ru}</td>
                <td>{type.iconType}</td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-warning me-3"
                    onClick={() => handleEdit(type)}
                  >
                    Edit
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleDelete(type.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PropertyTypeManager;
