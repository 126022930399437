import React from "react";
import { Link } from "react-router-dom";
import EditProfile from "../../../assets/icons/EditProfile.svg";
import HeaderAccount from "../../../components/HeaderAccount";
import DefaultProfileImage from "../../../assets/images/default-profile-photo.jpg";
import { useTranslation } from "react-i18next";
const AccountDetails = () => {
  const { t } = useTranslation();
  document.title = t("accountDetails.0");
  const headerTitle = t("accountDetails.0");
  const backwardNavigate = "/account";
  const storedUserInfo = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="account-details-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />

      <div className="top-section">
        <div className="username-section d-flex gap-3 align-items-center justify-content-center">
          <div className="left-section">
            <img
              src={storedUserInfo?.image || DefaultProfileImage}
              alt="profile"
            />
          </div>
          <div className="right-section d-flex align-items-center justify-content-between w-100">
            <div className="name-user-section mt-3 d-flex flex-column aling-items-center">
              <div className="name-section">
                <h1>
                  {" "}
                  {storedUserInfo?.name || "Ad"} &nbsp;
                  {storedUserInfo?.surname || "Soyad"}
                </h1>
              </div>
              <div className="user-section">
                <p>
                  {t("accountDetails.1")} :{" "}
                  {storedUserInfo?.orderNumber || "0000"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="edit-profile-section">
          <Link to="/account/details/edit">
            <button className="btn">
              <div className="d-flex align-items-center justify-content-between inner-section">
                <div className="left-section">
                  <h2 className="mb-0">{t("accountDetails.2")}</h2>
                </div>
                <div className="right-section">
                  <img src={EditProfile} alt="edit profile" />
                </div>
              </div>
            </button>
          </Link>
        </div>
      </div>

      <div className="middle-section">
        <form action="">
          <div className="name-surname-section">
            <label htmlFor="">{t("accountDetails.3")}</label>
            <input
              type="text"
              defaultValue={storedUserInfo?.name || "Ad"}
              readOnly
            />
            <label htmlFor="">{t("accountDetails.4")}</label>
            <input
              type="text"
              defaultValue={storedUserInfo?.surname || "Soyad"}
              readOnly
            />
          </div>
          <div className="email-section">
            <label htmlFor="">{t("accountDetails.5")}</label>
            <input
              type="text"
              defaultValue={storedUserInfo?.email || "example@gmail.com"}
              readOnly
            />
          </div>
          <div className="phone-number-section">
            <label htmlFor="">{t("accountDetails.6")}</label>
            <input
              type="text"
              defaultValue={storedUserInfo?.phoneNumber || "+9941234567"}
              readOnly
            />
          </div>
          <div className="adress-section">
            <label htmlFor="">{t("accountDetails.7")}</label>
            <input
              type="text"
              defaultValue={storedUserInfo?.address || "Bakı şəhəri"}
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountDetails;
