import React, { useState } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
const PrivacyPolicy = () => {
  document.title = "Məxfilik siyasəti";
  const headerTitle = "Məxfilik siyasəti";
  const backwardNavigate = "/account";
  const [openTitle, setOpenTitle] = useState(null);
  const handleToggle = (id) => {
    setOpenTitle(openTitle === id ? null : id);
  };
  const privacyPolicyTitle = [
    {
      id: 1,
      title: "Kantent",
      description:
        "Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm olaraq nəzərdən keçirməyi tövsiyə edirik.",
    },
    {
      id: 2,
      title: "İdarəetmə",
      description:
        "Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm olaraq nəzərdən keçirməyi tövsiyə edirik.",
    },
    {
      id: 3,
      title: "İstifadə olunan məlumatlar",
      description:
        "Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm olaraq nəzərdən keçirməyi tövsiyə edirik.",
    },
    {
      id: 4,
      title: "Şəxsi məlumatlar",
      description:
        "Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm olaraq nəzərdən keçirməyi tövsiyə edirik.",
    },
    {
      id: 5,
      title: "Kukilər",
      description:
        "Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm olaraq nəzərdən keçirməyi tövsiyə edirik.",
    },
    {
      id: 6,
      title: "Kommunikasiya məlumatları",
      description:
        "Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm olaraq nəzərdən keçirməyi tövsiyə edirik.",
    },
  ];
  return (
    <div className="privacy-policy-section px-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="top-section">
        <h5>Məxfi məlumatlarınızı detallı olaraq aşağıda görə bilərsiniz.</h5>
        <p>
          Biz bu Məxfilik Siyasətini zaman-zaman dəyişə bilərik. Dəyişikliklər
          etsək, tarixə yenidən baxaraq sizi xəbərdar edəcəyik bu siyasətin
          yuxarı hissəsində və bəzi hallarda biz sizə əlavə bildiriş təqdim edə
          bilərik (məsələn, vebsaytımıza bəyanat əlavə etmək və ya sizə bildiriş
          təqdim etmək). Məlumat təcrübələrimiz və sizin üçün mövcud seçimlər
          haqqında məlumatlı olmaq üçün sizi bu Məxfilik Siyasətini müntəzəm
          olaraq nəzərdən keçirməyi tövsiyə edirik.
        </p>
      </div>
      <div className="bottom-section">
        <hr />
        {privacyPolicyTitle.map((type) => (
          <div className="col-12" key={type.id}>
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="privacy-policy-title">{type.title}</h3>
              <i
                className={
                  openTitle === type.id
                    ? "fa-solid fa-chevron-down"
                    : "fa-solid fa-chevron-right"
                }
                onClick={() => handleToggle(type.id)}
              ></i>
            </div>
            <hr />
            {openTitle === type.id && (
              <div
                className={`description ${openTitle === type.id ? "open" : ""}`}
              >
                <p>{type.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
