import React, { useState, useEffect } from "react";
import axios from "axios";

const RepairManManager = () => {
  const [repairMans, setRepairMans] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    technicalArea: { id: null },
    image: {
      id: null,
      status: true,
    },
    status: true,
  });
  const [repairMan, setRepairMan] = useState([]);
  const [technicalAreas, setTechnicalAreas] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchTechnicalAreas();
    fetchRepairMan();
  }, []);

  const fetchTechnicalAreas = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/technical-areas/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setTechnicalAreas(response.data);
      })
      .catch((error) =>
        console.error("Failed to fetch technical areas:", error)
      );
  };

  const fetchRepairMan = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repairman/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setRepairMan(response.data);
      })
      .catch((error) => console.error("Failed to fetch repairMan:", error));
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event) => {
    const fileInput = event.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          setRepairMans((prevRepairMan) => ({
            ...prevRepairMan,
            image: { ...prevRepairMan.image, id: imageId },
          }));
        })
        .catch((error) => console.log("Failed to upload image:", error));
    } else {
      console.log("No file selected");
    }
  };

  const handleTechnicalAreaChange = (event) => {
    const selectedId = event.target.value;
    setRepairMans((prevRepairMan) => ({
      ...prevRepairMan,
      technicalArea: { id: Number(selectedId) },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const repairMansData = { ...repairMans };

    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repairman/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repairman";

    const request = isEditing
      ? axios.put(url, repairMansData, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, repairMansData, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRepairMans({
          name: "",
          surname: "",
          phoneNumber: "",
          image: { id: null, status: true },
          technicalArea: { id: null },
          status: true,
        });
        fetchRepairMan();
        alert("RepairMan saved successfully!");
      })
      .catch((error) => {
        console.error("Failed to submit repairMans:", error);
        alert(`Error: ${error.response?.data?.message || "Unknown error"}`);
      });
  };
  const handleEdit = (type) => {
    setRepairMans(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repairman/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairMan())
      .catch((error) => console.log(error));
  };
  return (
    <div className="repairMans-manager px-3">
      <h3 className="text-center text-primary">RepairMan Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div>
          <label>Name</label>
          <input
            type="text"
            value={repairMans.name}
            onChange={(e) =>
              setRepairMans({ ...repairMans, name: e.target.value })
            }
            required
          />
          <label>Surname</label>
          <input
            type="text"
            value={repairMans.surname}
            onChange={(e) =>
              setRepairMans({ ...repairMans, surname: e.target.value })
            }
            required
          />
          <label>Phone Number</label>
          <input
            type="text"
            value={repairMans.phoneNumber}
            onChange={(e) =>
              setRepairMans({ ...repairMans, phoneNumber: e.target.value })
            }
            required
          />
          <label>Upload Image</label>
          <input
            type="file"
            onChange={handleImageUpload}
            required={!isEditing}
          />
          <label>Technical Area</label>
          <select
            value={repairMans.technicalArea.id || ""}
            onChange={handleTechnicalAreaChange}
            required
          >
            <option value="" disabled>
              Select Technical Area
            </option>
            {technicalAreas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name.az}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-primary">
          {isEditing ? "Update" : "Submit"}
        </button>
      </form>
      <h5 className="text-center my-3">My Repair man Types</h5>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                №
              </th>
              <th scope="col">Name</th>
              <th scope="col">Surname</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Techinal Area (az)</th>
              <th scope="col">Techinal Area (en)</th>
              <th scope="col">Techinal Area (ru)</th>
              <th scope="col">Image</th>
              <th scope="col" className="text-center">
                Edit
              </th>
              <th scope="col" className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {repairMan.map((type, index) => {
              const base64String = `data:image/png;base64,${type.image.hashBase64}`;
              return (
                <tr key={type.id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td>{type.name}</td>
                  <td>{type.surname}</td>
                  <td>{type.phoneNumber}</td>
                  <td>{type.technicalArea.name.az}</td>
                  <td>{type.technicalArea.name.en}</td>
                  <td>{type.technicalArea.name.ru}</td>
                  <td>
                    {" "}
                    <img
                      src={base64String}
                      width={150}
                      alt={type.name}
                      className="design-style-image"
                    />
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-warning me-3"
                      onClick={() => handleEdit(type)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(type.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RepairManManager;
