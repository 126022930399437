import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const MaterialCategoryContext = createContext("");

export const MaterialCategoryProvider = ({ children }) => {
  const [materialCategory, setMaterialCategory] = useState([]);
  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState(
    JSON.parse(sessionStorage.getItem("selectedMaterialCategory")) || null
  );

  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories")
      .then((response) => {
        setMaterialCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching material category:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedMaterialCategory) {
      sessionStorage.setItem(
        "selectedMaterialCategory",
        JSON.stringify(selectedMaterialCategory)
      );
    }
  }, [selectedMaterialCategory]);

  return (
    <MaterialCategoryContext.Provider
      value={[
        materialCategory,
        setSelectedMaterialCategory,
        selectedMaterialCategory,
      ]}
    >
      {children}
    </MaterialCategoryContext.Provider>
  );
};
