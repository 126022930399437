import axios from "axios";
import React, { useEffect, useState } from "react";

const RoomTypeManager = () => {
  const [roomTypeAdmin, setRoomTypeAdmin] = useState([]);
  const [roomType, setRoomType] = useState({
    name: { az: "", en: "", ru: "" },
    space: {
      id: null,
    },
    iconType: "",
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [space, setSpace] = useState([]);
  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    fetchRoomTypes();
    fetchSpace();
  }, []);
  const handleSpaceChange = (event) => {
    const selectedId = event.target.value;
    setRoomType((prevRoomType) => ({
      ...prevRoomType,
      space: { id: Number(selectedId) }, // Setting the ID of the selected space
    }));
  };

  const fetchSpace = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/spaces/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setSpace(response.data);
      })
      .catch((error) =>
        console.error("Failed to fetch technical areas:", error)
      );
  };
  const fetchRoomTypes = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/room-types/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRoomTypeAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value, dataset } = event.target;
    const field = dataset.field;

    if (["az", "en", "ru"].includes(name)) {
      if (field === "name") {
        setRoomType((prevType) => ({
          ...prevType,
          name: { ...prevType.name, [name]: value },
        }));
      } else if (field === "spaceName") {
        setRoomType((prevType) => ({
          ...prevType,
          space: {
            ...prevType.space,
            name: { ...prevType.space.name, [name]: value },
          },
        }));
      }
    } else {
      setRoomType((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const axiosMethod = isEditing ? axios.put : axios.post;
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/room-types/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/room-types";

    axiosMethod(url, roomType, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRoomType({
          name: { az: "", en: "", ru: "" },
          space: {
            id: null,
          },
          iconType: "",
          status: true,
        });
        fetchRoomTypes();
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (type) => {
    setRoomType(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/room-types/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRoomTypes())
      .catch((error) => console.log(error));
  };

  return (
    <div className="room-types-manager px-3">
      <h3 className="text-center text-primary">Room Types Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="room-types-name">
          <h5 className="text-center">Room Types Name</h5>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={roomType.name.az}
            onChange={handleInput}
            name="az"
            data-field="name"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={roomType.name.en}
            onChange={handleInput}
            name="en"
            data-field="name"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={roomType.name.ru}
            onChange={handleInput}
            name="ru"
            data-field="name"
            required
          />
        </div>

        <div className="room-types-space-name">
          <h5 className="text-center">Space Select</h5>
          <select
            value={roomType.space.id || ""}
            onChange={handleSpaceChange}
            required
          >
            <option value="" disabled>
              Select Space
            </option>
            {space.map((space) => (
              <option key={space.id} value={space.id}>
                {space.name.az}
              </option>
            ))}
          </select>
        </div>

        <div className="room-types-icon-type">
          <h5 className="text-center">Icon Type</h5>
          <input
            type="text"
            value={roomType.iconType}
            onChange={handleInput}
            name="iconType"
            required
          />
        </div>

        <button className="btn btn-primary">
          {isEditing ? "Edit" : "Submit"}
        </button>
      </form>

      <h5 className="text-center my-3">My Room Types Types</h5>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Space (az)</th>
              <th scope="col">Space (en)</th>
              <th scope="col">Space (ru)</th>
              <th scope="col">Icon Type</th>
              <th scope="col" className="text-center">
                Edit
              </th>
              <th scope="col" className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {roomTypeAdmin.map((type, index) => (
              <tr key={type.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                <td>{type.name.az}</td>
                <td>{type.name.en}</td>
                <td>{type.name.ru}</td>
                <td>{type.space.name.az}</td>
                <td>{type.space.name.en}</td>
                <td>{type.space.name.ru}</td>
                <td>{type.iconType}</td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-warning me-3"
                    onClick={() => handleEdit(type)}
                  >
                    Edit
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleDelete(type.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomTypeManager;
