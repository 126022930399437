import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderAccount from "../../../components/HeaderAccount";
import axios from "axios";
import { SquareLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
const HomePlan = () => {
  const { t } = useTranslation();
  document.title = t("homePlan.0");
  const headerTitle = t("homePlan.0");
  const backwardNavigate = "/account";
  const navigate = useNavigate();

  const handlePropertyClick = (path) => {
    navigate(`/account/homeplan/${path}`);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [shareId, setShareId] = useState("");
  const [sharedRepair, setSharedRepair] = useState({});
  const repairId = localStorage.getItem("repairId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/share/${repairId}`)
      .then((response) => {
        const result = response.data.split("/").slice(6).join("/");
        setShareId(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [repairId]);

  useEffect(() => {
    if (shareId) {
      setIsLoading(true);
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${shareId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSharedRepair(response.data);
          console.log(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [shareId, token]);

  const property = sharedRepair.property;

  return (
    <div className="account-home-plan-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="middle-section ">
        <div className="row g-1">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#f5f5f5",
              }}
            >
              <SquareLoader
                color="#8c920b"
                cssOverride={{
                  position: "absolute",
                  top: "45%",
                  bottom: "45%",
                }}
                size={100}
                speedMultiplier={1}
              />
            </div>
          ) : property ? (
            <div className="col-6" key={property.id}>
              <div
                className="box d-flex align-items-center justify-content-center flex-column"
                onClick={() =>
                  handlePropertyClick(property.propertyType.name.text)
                }
                style={{ cursor: "pointer" }}
              >
                <div className="property-icon">
                  <i className={`${property.propertyType.iconType}`}></i>
                </div>
                <div className="property-title text-center">
                  <h5>{property.propertyType.name.text}</h5>
                </div>
              </div>
            </div>
          ) : (
            <h4 className="text-center mt-4">{t("homePlan.1")}</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePlan;
