import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SquareLoader } from "react-spinners";

const Shops = () => {
  document.title = "Mağazalar";
  const headerTitle = "Mağazalar";
  const navigate = useNavigate();
  const { id } = useParams();
  const [shops, setShops] = useState([]);
  const [filteredShops, setFilteredShops] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    if (!token) {
      navigate("/signup");
      return;
    }
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/stores/byMaterial/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShops(response.data); // Set initial data
        setFilteredShops(response.data); // Initial filtered list is the same as shops
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [id, token, navigate]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, shops]);

  const handleSearch = () => {
    const searchTermLower = searchTerm.toLowerCase();

    const filtered = shops.map((shopGroup) =>
      shopGroup.filter(
        (shop) =>
          typeof shop?.name?.text === "string" &&
          shop.name.text.toLowerCase().includes(searchTermLower)
      )
    );

    setFilteredShops(filtered);
  };

  // Function to remove duplicates based on shop id
  const removeDuplicateShops = (shops) => {
    const uniqueShops = [];
    const seenShopIds = new Set();

    shops.forEach((shopGroup) => {
      shopGroup.forEach((shop) => {
        if (!seenShopIds.has(shop.id)) {
          seenShopIds.add(shop.id);
          uniqueShops.push(shop);
        }
      });
    });

    return uniqueShops;
  };

  const uniqueShops = removeDuplicateShops(filteredShops);

  return (
    <div className="shops-section px-3">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <>
          <Header title={headerTitle} showProfile={true} />
          <div className="search-section">
            <div className="input-section d-flex align-items-center gap-2">
              <input
                type="text"
                placeholder="Axtar"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i
                className="fa-solid fa-magnifying-glass"
                onClick={handleSearch}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          <div className="row gap-3 px-3">
            {uniqueShops.map((item, index) => {
              const base64String = `data:image/png;base64,${item.image?.hashBase64}`;
              return (
                <div
                  className="col-12 shop-detail-section d-flex align-items-center"
                  key={item.id}
                >
                  <div className="left-section d-flex align-items-center justify-content-around gap-3">
                    <div className="image-section">
                      <img src={base64String} alt={item.name?.text || "Shop"} />
                    </div>
                    <div className="name-phone-section d-flex flex-column gap-2">
                      <div className="name-section">
                        <h6>{item.name?.text || "No Name"}</h6>
                      </div>
                      <div className="phone-section">
                        <p>{item.phoneNumber || "No Phone"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="right-section ms-auto me-4 mt-4">
                    <a
                      href={`tel:${item.phoneNumber || ""}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <i className="fa-solid fa-phone"></i>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Shops;
