import axios from "axios";
import React, { useEffect, useState } from "react";

const RepairTariffManager = () => {
  const [repairTariffAdmin, setRepairTariffAdmin] = useState([]);
  const [repairTariff, setRepairTariff] = useState({
    name: { az: "", en: "", ru: "" },
    description: { az: "", en: "", ru: "" },
    minValue: null,
    maxValue: null,
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchRepairTariffs();
  }, []);

  const fetchRepairTariffs = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-tariffs/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRepairTariffAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value, dataset } = event.target;
    const field = dataset.field;

    if (["az", "en", "ru"].includes(name)) {
      setRepairTariff((prevType) => ({
        ...prevType,
        [field]: { ...prevType[field], [name]: value },
      }));
    } else {
      const parsedValue =
        name === "minValue" || name === "maxValue" ? Number(value) : value;
      setRepairTariff((prevType) => ({ ...prevType, [name]: parsedValue }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const axiosMethod = isEditing ? axios.put : axios.post;
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repair-tariffs/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repair-tariffs";

    axiosMethod(url, repairTariff, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRepairTariff({
          name: { az: "", en: "", ru: "" },
          description: { az: "", en: "", ru: "" },
          minValue: null,
          maxValue: null,
          status: true,
        });
        fetchRepairTariffs();
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (type) => {
    setRepairTariff({
      name: type.name,
      description: type.description,
      minValue: type.minValue,
      maxValue: type.maxValue,
      status: type.status,
    });
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repair-tariffs/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairTariffs())
      .catch((error) => console.log(error));
  };

  return (
    <div className="repair-tariff-manager px-3">
      <h3 className="text-center text-primary">Repair Tariff Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="repair-tariff-name">
          <h5 className="text-center">Repair Tariff Name</h5>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={repairTariff.name.az}
            onChange={handleInput}
            name="az"
            data-field="name"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={repairTariff.name.en}
            onChange={handleInput}
            name="en"
            data-field="name"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={repairTariff.name.ru}
            onChange={handleInput}
            name="ru"
            data-field="name"
            required
          />
        </div>

        <div className="repair-tariff-description">
          <h5 className="text-center">Repair Tariff Description</h5>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={repairTariff.description.az}
            onChange={handleInput}
            name="az"
            data-field="description"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={repairTariff.description.en}
            onChange={handleInput}
            name="en"
            data-field="description"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={repairTariff.description.ru}
            onChange={handleInput}
            name="ru"
            data-field="description"
            required
          />
        </div>
        <div className="Average-value">
          <h5>Average Value</h5>
          <label htmlFor="minValue">Min Price</label>
          <input
            type="number"
            value={repairTariff.minValue || ""}
            onChange={handleInput}
            name="minValue"
            required
          />
          <label htmlFor="maxValue">Max Price</label>
          <input
            type="number"
            value={repairTariff.maxValue || ""}
            onChange={handleInput}
            name="maxValue"
            required
          />
        </div>
        <button className="btn btn-primary">
          {isEditing ? "Edit" : "Submit"}
        </button>
      </form>

      <h5 className="text-center my-4">My Repair Tariff Types</h5>
      <div className="d-flex flex-column gap-3">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  №
                </th>
                <th scope="col">Name (az)</th>
                <th scope="col">Name (en)</th>
                <th scope="col">Name (ru)</th>
                <th scope="col">Description (az)</th>
                <th scope="col">Description (en)</th>
                <th scope="col">Description (ru)</th>
                <th scope="col">Min Price</th>
                <th scope="col">Max Price</th>
                <th scope="col" className="text-center">
                  Edit
                </th>
                <th scope="col" className="text-center">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {repairTariffAdmin.map((type, index) => (
                <tr key={type.id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td>{type.name.az}</td>
                  <td>{type.name.en}</td>
                  <td>{type.name.ru}</td>
                  <td>{type.description.az}</td>
                  <td>{type.description.en}</td>
                  <td>{type.description.ru}</td>
                  <td>{type.minValue}</td>
                  <td>{type.maxValue}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-warning me-3"
                      onClick={() => handleEdit(type)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(type.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RepairTariffManager;
