import React, { useEffect, useState } from "react";
import RatingStar from "../../../assets/icons/RatingStar.svg";
import CellPhone from "../../../assets/icons/CellPhone.svg";
import HeaderAccount from "../../../components/HeaderAccount";
import axios from "axios";
import { useTranslation } from "react-i18next";

const MyForeman = () => {
  const { t } = useTranslation();
  document.title = t("myForeman.0");
  const headerTitle = t("myForeman.0");
  const backwardNavigate = "/account";
  const [repairman, setRepairMan] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repairman", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRepairMan(response.data);
      })
      .catch((error) => console.error("Failed to fetch repairMan:", error));
  }, []);
  console.log(repairman);
  return (
    <div className="myforeman-section px-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="middle-section">
        {repairman.map((type, index) => {
          const base64String = `data:image/png;base64,${type.image.hashBase64}`;
          return (
            <div key={index}>
              <div className="foreman-details-section d-flex gap-3 align-items-center justify-content-center">
                <div className="left-section">
                  <img src={base64String} alt="profile" />
                </div>
                <div className="right-section d-flex align-items-center justify-content-between w-100">
                  <div className="name-user-section d-flex flex-column aling-items-center">
                    <div className="name-section">
                      <h1>
                        {type.name} {type.surname}
                      </h1>
                    </div>
                    <div className="user-section d-flex gap-2">
                      <img src={RatingStar} alt="star" />
                      <p>{type.technicalArea.name.text}</p>
                    </div>
                  </div>
                  <div className="detail-section me-2">
                    <a href={`tel:${type.phoneNumber}`} className="btn">
                      <img src={CellPhone} alt="Call" />
                    </a>
                  </div>
                </div>
              </div>
              <hr className="m-0" />
            </div>
          );
        })}
      </div>
      <div className="bottom-section mt-5">
        <button className="global-button w-100 py-3">{t("myForeman.1")}</button>
      </div>
    </div>
  );
};

export default MyForeman;
