import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

const OTPverification = () => {
  document.title = "OTP Təsdiqlənməsi";
  const headerTitle = "OTP təsdiq";
  const [time, setTime] = useState(60);

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [time]);

  const formatTime = time === 0 ? "00" : time;

  const [values, setValues] = useState({
    data1: "",
    data2: "",
    data3: "",
    data4: "",
    data5: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      setValues({
        ...values,
        [name]: value,
      });

      // Move to next input automatically
      if (value.length === 1) {
        const nextInput = document.querySelector(
          `input[name=data${parseInt(name.slice(4)) + 1}]`
        );
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  return (
    <div className="otpverification-section mx-3">
      <Header title={headerTitle} showProfile={false} />
      <div className="top-section">
        <h2>Nömrənin təsdiqlənməsi</h2>
        <p>(+994) 55-533-41-11 nömrəsinə SMS-kod göndərildi</p>
      </div>
      <div className="bottom-section">
        <p>SMS-kod </p>
        <form>
          <div className="d-flex align-items-center justify-content-between gap-3 mb-4">
            <input
              type="number"
              name="data1"
              value={values.data1}
              onChange={handleChange}
            />
            <input
              type="number"
              name="data2"
              value={values.data2}
              onChange={handleChange}
            />
            <input
              type="number"
              name="data3"
              value={values.data3}
              onChange={handleChange}
            />
            <input
              type="number"
              name="data4"
              value={values.data4}
              onChange={handleChange}
            />
            <input
              type="number"
              name="data5"
              value={values.data5}
              onChange={handleChange}
            />
          </div>
        </form>

        <h6>SMS-kod yenidən göndərilsin 00:{formatTime}</h6>
      </div>
      <div className="button-section">
        <Link to="/successlogin">
          <button className="btn py-3 global-button">Davam et</button>
        </Link>
      </div>
    </div>
  );
};

export default OTPverification;
