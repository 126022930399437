import React from "react";
import { Link } from "react-router-dom";
import MaintenanceStatus from "../../assets/icons/MaintenanceStatus.svg";
import MaintenanceHistory from "../../assets/icons/MaintenanceHistory.svg";
import HomePlan from "../../assets/icons/HomePlan.svg";
import Contact from "../../assets/icons/Contact.svg";
import Privacy from "../../assets/icons/Privacy.svg";
import Terms from "../../assets/icons/Terms.svg";
import Languages from "../../assets/icons/Languages.svg";
import Exit from "../../assets/icons/Exit.svg";
import Rus from "../../assets/icons/Rus.svg";
import Aze from "../../assets/icons/Aze.svg";
import Eng from "../../assets/icons/Eng.svg";
import MyHome from "../../assets/icons/MyHome.svg";
import MyMaster from "../../assets/icons/MyMaster.svg";
import Header from "../../components/Header";
import DefaultProfileImage from "../../assets/images/default-profile-photo.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
const Account = () => {
  const { t } = useTranslation();
  document.title = t("account.0");
  const headerTitle = t("account.0");
  const storedUserInfo = JSON.parse(localStorage.getItem("user"));
  const exitAccount = () => {
    window.location.assign("/home");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };
  const toggleLang = (lang) => {
    i18next.changeLanguage(lang);
  };
  return (
    <div className="account-section mx-3">
      <Header title={headerTitle} showProfile={false} />
      <div className="top-section">
        <div className="username-section d-flex gap-3 align-items-center justify-content-center">
          <div className="left-section">
            <img
              src={storedUserInfo?.image || DefaultProfileImage}
              alt="profile"
            />
          </div>
          <div className="right-section d-flex align-items-center justify-content-between w-100">
            <div className="name-user-section mt-3 d-flex flex-column">
              <div className="name-section">
                <h1>
                  {storedUserInfo?.name || "Ad"} &nbsp;
                  {storedUserInfo?.surname || "Soyad"}
                </h1>
              </div>
              <div className="user-section">
                <p>
                  {t("account.1")} : {storedUserInfo?.orderNumber || "0000"}
                </p>
              </div>
            </div>
            <div className="detail-section me-2">
              <Link to="/account/details">
                <i className="fa-solid fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="maintenance-section d-flex align-items-center justify-content-between gap-3 ">
          <div
            className="section-1 section"
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#maintenanceStatus"
          >
            <img src={MaintenanceStatus} alt="maintenance status" />
            <h5>{t("account.2")}</h5>
          </div>
          <div className="section-2 section">
            <Link className="text-decoration-none" to="/account/repairhistory">
              <img src={MaintenanceHistory} alt="maintenance history" />
              <h5>{t("account.3")}</h5>
            </Link>
          </div>
          <div className="section-3 section">
            <Link className="text-decoration-none" to="/account/homeplan">
              <img src={HomePlan} alt="home plan" />
              <h5>{t("account.4")}</h5>
            </Link>
          </div>
        </div>
      </div>
      <div className="middle-section">
        <div className="my-house-foreman-section mb-3">
          <div className="connection-section">
            <Link to="/account/myhome" className="text-decoration-none">
              <div className="section section-1 d-flex align-items-center">
                <div className="left-section">
                  <img src={MyHome} alt="contact" />
                </div>
                <div className="right-section d-flex align-items-center justify-content-between w-100">
                  <h2 className="mt-2">{t("account.5")}</h2>
                  <div className="detail-section me-2">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/account/myforeman" className="text-decoration-none">
              <div className="section section-2 d-flex align-items-center">
                <div className="left-section">
                  <img src={MyMaster} alt="contact" />
                </div>
                <div className="right-section d-flex align-items-center justify-content-between w-100">
                  <h2 className="mt-2">{t("account.6")}</h2>
                  <div className="detail-section me-2">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="connection-section">
          <Link to="/account/contact" className="text-decoration-none">
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={Contact} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("account.7")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
          <div
            className="section section-2 d-flex align-items-center"
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#languageModal"
          >
            <div className="left-section">
              <img src={Languages} alt="languages" />
            </div>
            <div className="right-section d-flex align-items-center justify-content-between w-100">
              <h2 className="mt-2">{t("account.8")}</h2>
              <div className="detail-section me-2">
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Languages */}
      <div
        className="modal fade"
        id="languageModal"
        tabIndex="-1"
        aria-labelledby="languageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item"
                  data-bs-dismiss="modal"
                  onClick={() => toggleLang("az")}
                >
                  <img src={Aze} alt="azerbaijan flag" />
                  <span className="ms-3">{t("account.9")}</span>
                  <hr className="mb-0 mt-3" />
                </li>
                <li
                  className="list-group-item"
                  data-bs-dismiss="modal"
                  onClick={() => toggleLang("en")}
                >
                  <img src={Eng} alt="england flag" />
                  <span className="ms-3">{t("account.10")}</span>
                  <hr className="mb-0 mt-3" />
                </li>
                <li
                  className="list-group-item"
                  data-bs-dismiss="modal"
                  onClick={() => toggleLang("ru")}
                >
                  <img src={Rus} alt="russian flag" />
                  <span className="ms-3">{t("account.11")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for maintenance status */}
      <div
        className="modal fade"
        id="maintenanceStatus"
        tabIndex="-1"
        aria-labelledby="maintenanceStatusLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item" data-bs-dismiss="modal">
                  <input type="radio" id="customRadio" name="customRadio" />
                  <label className="ms-3">{t("account.12")}</label>
                  <hr className="mb-0 mt-3" />
                </li>
                <li className="list-group-item" data-bs-dismiss="modal">
                  <input type="radio" id="customRadio" name="customRadio" />
                  <label className="ms-3">{t("account.13")}</label>
                  <hr className="mb-0 mt-3" />
                </li>
                <li className="list-group-item" data-bs-dismiss="modal">
                  <input type="radio" id="customRadio" name="customRadio" />
                  <label className="ms-3">{t("account.14")}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <h6 className="mb-2 ms-1">{t("account.15")}</h6>
        <div className="others-section">
          <Link to="/account/privacypolicy" className="text-decoration-none">
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={Privacy} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("account.16")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/account/terms" className="text-decoration-none">
            <div className="section section-2 d-flex align-items-center">
              <div className="left-section">
                <img src={Terms} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("account.17")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="exit-section">
        <button
          className="btn w-100 p-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <div className="section section-1 d-flex align-items-center">
            <div className="left-section">
              <img src={Exit} alt="contact" />
            </div>
            <div className="right-section d-flex align-items-center justify-content-between w-100">
              <h2 className="mt-2">{t("account.18")}</h2>
              <div className="detail-section me-2">
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </button>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <h6 className="text-center my-auto">{t("account.19")}</h6>
            </div>
            <div class="exit-footer d-flex align-items-center justify-content-center gap-4 m-3">
              <button
                type="button"
                class="btn exit-button"
                onClick={exitAccount}
              >
                {t("account.20")}
              </button>
              <button
                type="button"
                class="btn cancel-button"
                data-bs-dismiss="modal"
              >
                {t("account.21")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
