import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProrabHeader2 from "../assets/images/ProrabHeader2.png";
import UserProfile from "../assets/icons/user_profile.svg";

const Header = ({ title, showProfile }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleProfileClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/account");
    } else {
      navigate("/signup");
    }
  };

  return (
    <header className="mt-3 d-flex align-items-center justify-content-between mb-3">
      <Link to="/home">
        <img src={ProrabHeader2} alt="eprorab logo" title="eprorab" />
      </Link>
      <div className="page-title">
        <h4>{title}</h4>
      </div>
      {showProfile ? (
        <img
          src={UserProfile}
          alt="user profile"
          onClick={handleProfileClick}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <div className="ms-4"></div>
      )}
    </header>
  );
};

export default Header;
