import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const MaterialContext = createContext(null);

export const MaterialProvider = ({ children }) => {
  const [material, setMaterial] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(
    JSON.parse(sessionStorage.getItem("selectedMaterial")) || null
  );
  const fetchMaterials = (categoryId, repairStyleId) => {
    axios
      .get(
        `https://api.emotix.dev/api/v1/e-prorab/materials/filter?categoyId=${categoryId}&repairStyleId=${repairStyleId}`
      )
      .then((response) => {
        setMaterial(response.data);
      })
      .catch((error) => {
        console.error("Error fetching materials:", error);
        setMaterial([]);
      });
  };

  useEffect(() => {
    if (selectedMaterial) {
      sessionStorage.setItem(
        "selectedMaterial",
        JSON.stringify(selectedMaterial)
      );
    }
  }, [selectedMaterial]);

  return (
    <MaterialContext.Provider
      value={[material, setSelectedMaterial, selectedMaterial, fetchMaterials]}
    >
      {children}
    </MaterialContext.Provider>
  );
};
