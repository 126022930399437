import React, { useEffect } from "react";
import SuccessLoginImg from "../../assets/images/SuccessLogin.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const SuccessLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const repairId = JSON.parse(localStorage.getItem("repairId"));
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/allmaterials/${repairId}`);
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);
  const handleSubmit = () => {
    navigate(`/allmaterials/${repairId}`);
  };
  return (
    <div className="succcesslogin-section">
      <img src={SuccessLoginImg} alt="success" onClick={handleSubmit} />
      <h4 className="text-center">{t("successLogin.0")}</h4>
      <p className="text-center">{t("successLogin.1")}</p>
    </div>
  );
};

export default SuccessLogin;
