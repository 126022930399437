import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { RoomTypeContext } from "../../context/RoomType";

const Dropdown = ({ onChange, selectedRoomTypes }) => {
  const [room, setRoom] = useState("");
  const [roomType] = useContext(RoomTypeContext) || [];
  const getAvailableRooms = () => {
    return selectedRoomTypes?.length ? selectedRoomTypes : roomType || [];
  };

  const getRoomDisplayName = (room) => {
    return room?.name?.text || room?.name || room?.id || "Unknown Room";
  };

  useEffect(() => {
    const availableRooms = getAvailableRooms();
    if (!room && availableRooms.length > 0) {
      const defaultRoom = availableRooms[0];
      setRoom(defaultRoom.id);
      onChange?.(defaultRoom);
      sessionStorage.setItem("selectedRoom", JSON.stringify(defaultRoom));
    }
  }, [roomType, selectedRoomTypes, room, onChange]);

  const handleChange = (event) => {
    const selectedRoom = getAvailableRooms().find(
      (room) => room.id === event.target.value
    );
    if (selectedRoom) {
      setRoom(selectedRoom.id);
      onChange?.(selectedRoom);
      sessionStorage.setItem("selectedRoom", JSON.stringify(selectedRoom));
    }
  };

  const availableRooms = getAvailableRooms() || [];

  if (!availableRooms.length) {
    return null;
  }

  return (
    <Box>
      <FormControl fullWidth>
        <Select value={room || ""} onChange={handleChange} className="w-100">
          {availableRooms.map((room) => (
            <MenuItem
              key={room.id || room.name || Math.random()}
              value={room.id}
            >
              {getRoomDisplayName(room)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Dropdown;
